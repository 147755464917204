import { SUPPLYCHAIN_TYPES } from "../actions/supplychainAction";

const initialState = {
  total: 0,
  products: [],
  supplychain: {},
};

const supplychainReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUPPLYCHAIN_TYPES.GET_SUPPLYCHAIN:
      return {
        ...state,
        total: action.payload.total,
        products: action.payload.products,
      };

    case SUPPLYCHAIN_TYPES.GET_SINGLE_SUPPLYCHAIN:
      return {
        ...state,
        supplychain: action.payload,
      };

    case SUPPLYCHAIN_TYPES.APPROVE_SINGLE_SUPPLYCHAIN:
      return {
        ...state,
        supplychain: { ...state.supplychain, isApproved: true },
      };

    case SUPPLYCHAIN_TYPES.DENY_SINGLE_SUPPLYCHAIN:
      return {
        ...state,
        supplychain: { ...state.supplychain, isApproved: undefined },
      };

    case SUPPLYCHAIN_TYPES.ADD_MATERIAL_SUPPLIER:
      return {
        ...state,
        products: state.products.map((product) => {
          if (product._id === action.payload.productId)
            product.materialSuppliers.push(action.payload.supplyChainInfo);
          if (!product.supplyChain)
            product.supplyChain = action.payload.supplyChainId;
          return product;
        }),
      };

    case SUPPLYCHAIN_TYPES.ADD_MANUFACTURING_FACILITIES:
      return {
        ...state,
        products: state.products.map((product) => {
          if (product._id === action.payload.productId)
            product.manufacturingFacilities.push(
              action.payload.supplyChainInfo
            );
          if (!product.supplyChain)
            product.supplyChain = action.payload.supplyChainId;
          return product;
        }),
      };

    case SUPPLYCHAIN_TYPES.ADD_WAREHOUSES:
      return {
        ...state,
        products: state.products.map((product) => {
          if (product._id === action.payload.productId)
            product.warehouses.push(action.payload.supplyChainInfo);
          if (!product.supplyChain)
            product.supplyChain = action.payload.supplyChainId;
          return product;
        }),
      };

    case SUPPLYCHAIN_TYPES.EDIT_MATERIAL_SUPPLIER:
      return {
        ...state,
        products: state.products.map((product) => {
          product.materialSuppliers = product.materialSuppliers.map((obj) => {
            if (obj._id === action.payload.id) return action.payload.data;
            return obj;
          });
          return product;
        }),
      };

    case SUPPLYCHAIN_TYPES.EDIT_MANUFACTURING_FACILITIES:
      return {
        ...state,
        products: state.products.map((product) => {
          product.manufacturingFacilities = product.manufacturingFacilities.map(
            (obj) => {
              if (obj._id === action.payload.id) return action.payload.data;
              return obj;
            }
          );
          return product;
        }),
      };

    case SUPPLYCHAIN_TYPES.EDIT_WAREHOUSES:
      return {
        ...state,
        products: state.products.map((product) => {
          product.warehouses = product.warehouses.map((obj) => {
            if (obj._id === action.payload.id) return action.payload.data;
            return obj;
          });
          return product;
        }),
      };

    case SUPPLYCHAIN_TYPES.DELETE_SUPPLYCHAININFO:
      return {
        ...state,
        products: state.products.map((product) => {
          if (action.payload.type === "Material Supplier") {
            product.materialSuppliers = product.materialSuppliers.filter(
              (obj) => obj._id !== action.payload.id
            );
          } else if (action.payload.type === "Manufacturing Facility") {
            product.manufacturingFacilities =
              product.manufacturingFacilities.filter(
                (obj) => obj._id !== action.payload.id
              );
          } else if (action.payload.type === "Warehouse") {
            product.warehouses = product.warehouses.filter(
              (obj) => obj._id !== action.payload.id
            );
          }
          return product;
        }),
      };

    case SUPPLYCHAIN_TYPES.SUBMIT_SUPPLYCHAIN:
      return {
        ...state,
        products: state.products.map((product) => {
          if (product.supplyChain === action.payload)
            return { ...product, isApproved: false };
          return product;
        }),
      };

    default:
      return state;
  }
};

export default supplychainReducer;
