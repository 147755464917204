import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../components/common/Pagination";

import { getStockRequests } from "../../redux/actions/stockAction";
import SingleStockRequestMember from "../../components/stockrequest/SingleStockRequestMember";

const StockRequest = () => {
  const dispatch = useDispatch();
  const { totalStockRequests, stockRequests } = useSelector(
    (state) => state.stock
  );

  const [searchTerm, setSearchTerm] = useState("");
  const [sort, setSort] = useState("newest");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    if (searchTerm === "")
      dispatch(getStockRequests("", sort, rowsPerPage, page * rowsPerPage));
  }, [dispatch, searchTerm, sort, page, rowsPerPage]);

  useEffect(() => {
    const getData = setTimeout(() => {
      if (searchTerm !== "")
        dispatch(
          getStockRequests(searchTerm, sort, rowsPerPage, page * rowsPerPage)
        );
    }, 1000);

    return () => clearTimeout(getData);
  }, [dispatch, searchTerm, sort, page, rowsPerPage]);

  return (
    <div className="d-flex flex-column-fluid">
      {/* <!--begin::Container--> */}
      <div className="container">
        {/* <!--begin::Dashboard-->
								<!--begin::Row--> */}
        <div className="row mt-3">
          <div className="col-lg-12">
            <div className="card card-custom border-radius-18px">
              <div className="card-header border-0">
                <div className="card-title">
                  <div className="card-label">
                    Stock Requests{" "}
                    <small>
                      Total {totalStockRequests}{" "}
                      {totalStockRequests === 1
                        ? "Stock Request"
                        : "Stock Requests"}{" "}
                    </small>
                  </div>
                </div>
                <div className="card-toolbar">
                  <div className="form-group m-0 mr-2">
                    <input
                      type="text"
                      className="form-control form-control-solid pl-4"
                      placeholder="Search..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                  <div>
                    <select
                      className="form-control form-control-solid"
                      onChange={(e) => setSort(e.target.value)}
                    >
                      <option value="newest">Newest</option>
                      <option value="oldest">Oldest</option>
                      <option value="name">By Name</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <table className="table">
                  <thead className="thead-light">
                    <tr>
                      <th width="250px">Items</th>
                      <th width="250px">Supplier</th>
                      <th>Par Level</th>
                      <th>Requested Par Level</th>
                      <th>Requested Min Expiry</th>
                      <th>Status</th>
                      <th>Review</th>
                      <th>Invoice</th>
                    </tr>
                  </thead>
                  <tbody>
                    {stockRequests.map((stockRequest) => {
                      return (
                        <SingleStockRequestMember
                          key={stockRequest._id}
                          stockRequest={stockRequest}
                        />
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <Pagination
                count={totalStockRequests}
                page={page}
                rowsPerPage={rowsPerPage}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
              />
            </div>
          </div>
        </div>
        {/* <!--end::Dashboard--> */}
      </div>
      {/* <!--end::Container--> */}
    </div>
  );
};

export default StockRequest;
