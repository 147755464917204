import { PRODUCT_TYPES } from "../actions/productAction";

const initialState = {
  total: 0,
  products: [],
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case PRODUCT_TYPES.GET_PRODUCTS:
      return {
        ...state,
        total: action.payload.total,
        products: action.payload.products,
      };

    case PRODUCT_TYPES.CREATE_PRODUCT:
      return {
        ...state,
        total: state.total + 1,
        products: [action.payload, ...state.products],
      };

    case PRODUCT_TYPES.DELETE_PRODUCT:
      return {
        ...state,
        total: state.total - 1,
        products: state.products.filter(
          (product) => product._id !== action.payload
        ),
      };

    case PRODUCT_TYPES.EDIT_PRODUCT:
      return {
        ...state,
        products: state.products.map((product) => {
          if (product._id === action.payload.id) return action.payload.product;
          return product;
        }),
      };
    default:
      return state;
  }
};

export default productReducer;
