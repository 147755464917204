import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { editProfile } from "../../redux/actions/authAction";
import { BASE_URL } from "../../utils/fetchData";

const Profile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);

  const [profilePic, setProfilePic] = useState(user.profilePic);
  const [name, setName] = useState(user.name);
  const [email, setEmail] = useState(user.email);
  const [phone, setPhone] = useState(user.phone);
  const [address, setAddress] = useState(user.address);
  const [isFileUpdated, setIsFileUpdated] = useState(false);

  const handleClick = () => {
    document.getElementById("upload").click();
  };

  const handleFileSelected = (e) => {
    let file = e.target.files[0];
    setProfilePic(file);
    displayFile(file);
    setIsFileUpdated(true);
  };

  const displayFile = (file) => {
    const dragArea = document.querySelector(".profile-div");
    dragArea.removeChild(dragArea.firstElementChild);

    let fileReader = new FileReader();
    fileReader.onload = () => {
      let fileURL = fileReader.result;
      let imgTag = `<img src=${fileURL} alt="" class="img-fluid" width="200px" />`;
      //dragArea.insertBefore(imgTag, dragArea.firstChild);
      dragArea.innerHTML = imgTag;
    };
    fileReader.readAsDataURL(file);
  };

  const handleClose = () => {
    navigate("/");
  };

  const handleSubmit = () => {
    const payload = {
      profilePic: isFileUpdated && profilePic,
      name,
      email,
      phone,
      address,
    };

    dispatch(editProfile(payload));
  };

  return (
    <div className="d-flex flex-column-fluid">
      <div className="container">
        <div className="card card-custom">
          <div className="card-body">
            <div className="row justify-content-center mt-3">
              <div className="col-lg-6">
                <div className="text-center mb-5 profile-div">
                  <img
                    src={
                      profilePic
                        ? `${BASE_URL}/${profilePic}`
                        : "assets/media/users/default.jpg"
                    }
                    alt=""
                    className="img-fluid"
                    width="200px"
                  />
                </div>
                <p className="text-center">
                  <input
                    type="file"
                    id="upload"
                    className="d-none"
                    //style={{ visibility: "hidden" }}
                    accept="image/png, image/gif, image/jpeg"
                    onChange={handleFileSelected}
                  />
                  <a className="text-warning" onClick={handleClick}>
                    Change Profile Picture
                  </a>
                </p>
                <div>
                  <div className="form-group">
                    <label htmlFor="">Name</label>
                    <input
                      type="text"
                      className="form-control form-control-solid"
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="">Email</label>
                    <input
                      type="email"
                      className="form-control form-control-solid"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="">Phone</label>
                    <input
                      type="text"
                      className="form-control form-control-solid"
                      name="phone"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="">Address</label>
                    <textarea
                      className="form-control form-control-solid"
                      name="address"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    ></textarea>
                  </div>
                  <div className="card-footer border-0 text-right">
                    <button
                      className="btn btn-outline-dark btn-pill px-6"
                      type="button"
                      onClick={handleClose}
                    >
                      Cancel
                    </button>{" "}
                    <button
                      className="btn btn-gradient btn-pill px-6"
                      type="button"
                      onClick={handleSubmit}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
