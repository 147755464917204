import { NOTIFY_TYPES } from "../actions/notifyAction";

const initialState = {
  notifications: [],
  countUnseen: 0,
  total: 0,
};

const notifyReducer = (state = initialState, action) => {
  switch (action.type) {
    case NOTIFY_TYPES.GET_NOTIFICATIONS:
      return {
        ...state,
        total: action.payload.total,
        notifications: action.payload.notifications,
        countUnseen: action.payload.countUnseen,
      };

    case NOTIFY_TYPES.SEEN_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.map((notification) => {
          if (notification._id === action.payload)
            return { ...notification, isSeen: true, askForApproval: false };
          return notification;
        }),
        countUnseen: state.countUnseen - 1,
      };

    case NOTIFY_TYPES.GET_COUNT:
      return {
        ...state,
        countUnseen: action.payload,
      };

    default:
      return state;
  }
};

export default notifyReducer;
