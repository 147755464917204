export const routes = [
  {
    key: 1,
    route: "/",
    text: "Dashboard",
    icon: "flaticon2-architecture-and-city",
  },
  {
    key: 2,
    route: "/product",
    text: "Products",
    icon: "flaticon2-layers-1",
  },
  {
    key: 3,
    route: "/sure-supply-shield",
    text: "Reserve Drug Supply",
    icon: "flaticon2-notepad",
  },
  {
    key: 4,
    route: "/stock",
    text: "Assigned Stock",
    icon: "flaticon2-notepad",
  },
  {
    key: 5,
    route: "/stockrequests",
    text: "Stock Requests",
    icon: "flaticon2-notepad",
  },
  {
    key: 6,
    route: "/stockreplenishments",
    text: "Replenishments",
    icon: "flaticon2-notepad",
  },
  {
    key: 7,
    route: "/order",
    text: "Orders",
    icon: "flaticon2-list-2",
  },
  {
    key: 8,
    route: "/member",
    text: "Members",
    icon: "flaticon2-user-outline-symbol",
  },
  {
    key: 9,
    route: "/notifications",
    text: "Notifications",
    icon: "flaticon2-bell-4",
  },
  {
    key: 10,
    route: "/contact",
    text: "Contact",
    icon: "flaticon2-mail",
  },
];
