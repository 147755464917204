import { ORDER_TYPES } from "../actions/orderAction";

const initialState = {
  total: 0,
  totalExpiring: 0,
  orders: [],
  ordersExpiring: [],
  order: {},
};

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case ORDER_TYPES.GET_ORDERS:
      return {
        ...state,
        total: action.payload.total,
        orders: action.payload.orders,
      };

    case ORDER_TYPES.GET_ORDER:
      return {
        ...state,
        order: action.payload,
      };

    case ORDER_TYPES.GET_EXPIRING_LOT_ORDERS:
      return {
        ...state,
        totalExpiring: action.payload.total,
        ordersExpiring: action.payload.orders,
      };

    case ORDER_TYPES.DISPATCH_ORDER:
      return {
        ...state,
        orders: state.orders.map((order) => {
          if (order._id === action.payload.orderId) return action.payload.order;
          return order;
        }),
      };

    case ORDER_TYPES.COMPLETE_ORDER:
      return {
        ...state,
        orders: state.orders.map((order) => {
          if (order._id === action.payload.orderId) return action.payload.order;
          return order;
        }),
      };

    case ORDER_TYPES.APPROVE_ORDER:
      return {
        ...state,
        orders: state.orders.map((order) => {
          if (order._id === action.payload.orderId) return action.payload.order;
          return order;
        }),
      };

    case ORDER_TYPES.DISPATCH_EXPIRING_LOT_ORDER:
      return {
        ...state,
        ordersExpiring: state.ordersExpiring.map((order) => {
          if (order._id === action.payload.orderId) return action.payload.order;
          return order;
        }),
      };

    default:
      return state;
  }
};

export default orderReducer;
