import { SURESUPPLY_TYPES } from "../actions/sureSupplyAction";

const initialState = {
  stock: [],
  total: 0,
};

const sureSupplyReducer = (state = initialState, action) => {
  switch (action.type) {
    case SURESUPPLY_TYPES.GET_SURE_SUPPLY:
      return {
        ...state,
        total: action.payload.total,
        stock: action.payload.stock,
      };

    case SURESUPPLY_TYPES.ADD_STOCK_REQUEST:
      const { stockId, requestedParLevel, requestedMinExpiry } = action.payload;
      const newStock = state.stock.map((s) => {
        if (s._id === stockId)
          return { ...s, requestedParLevel, requestedMinExpiry };
        else return { ...s };
      });
      return {
        ...state,
        stock: newStock,
      };
    default:
      return state;
  }
};

export default sureSupplyReducer;
