import React from "react";
import { Route, Routes } from "react-router-dom";
import Profile from "../components/common/Profile";
import AssignedStock from "../components/stock/AssignedStock";
import PrivateRoute from "../customRouter/PrivateRoute";
import TransferStockDetail from "../pages/common/TransferStockDetail";
import Contact from "../pages/distributor/Contact";
import ExpiringLots from "../pages/distributor/ExpiringLots";
import Location from "../pages/distributor/Location";
import Notification from "../pages/distributor/Notification";
import Order from "../pages/distributor/Order";
import Product from "../pages/distributor/Product";
import Replenishment from "../pages/distributor/Replenishment";
import OrderDetail from "../pages/healthpro/OrderDetail";
import ProcessedOrderDetail from "../pages/healthpro/ProcessedOrderDetail";
import Layout from "../templates/DistributorLayout";
import Invoice from "../components/common/Invoice";

//pages
const Dashboard = React.lazy(() => import("../pages/distributor/Dashboard"));
const Login = React.lazy(() => import("../pages/common/Login"));
const OTP = React.lazy(() => import("../pages/common/OTP"));

const DistributorRoutes = () => {
  return (
    <Routes>
      <Route exact path="/login" element={<Login />} />
      <Route exact path="/verify" element={<OTP />} />
      <Route exact path="/invoice" element={<Invoice />} />
      <Route
        exact
        path="/"
        element={
          <PrivateRoute>
            <Layout>
              <Dashboard />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/me"
        element={
          <PrivateRoute>
            <Layout>
              <Profile />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/product"
        element={
          <PrivateRoute>
            <Layout>
              <Product />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/order"
        element={
          <PrivateRoute>
            <Layout>
              <Order />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/location"
        element={
          <PrivateRoute>
            <Layout>
              <Location />
            </Layout>
          </PrivateRoute>
        }
      />
      {/* <Route
        exact
        path="/replenishment"
        element={
          <PrivateRoute>
            <Layout>
              <Replenishment />
            </Layout>
          </PrivateRoute>
        }
      /> */}
      <Route
        exact
        path="/expiringlots"
        element={
          <PrivateRoute>
            <Layout>
              <ExpiringLots />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/notifications"
        element={
          <PrivateRoute>
            <Layout>
              <Notification />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/contact"
        element={
          <PrivateRoute>
            <Layout>
              <Contact />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/order/:id/new"
        element={
          <PrivateRoute>
            <Layout>
              <OrderDetail />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/order/:id/completed"
        element={
          <PrivateRoute>
            <Layout>
              <ProcessedOrderDetail />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/stocktransfer/:id"
        element={
          <PrivateRoute>
            <Layout>
              <TransferStockDetail />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/stock/:id"
        element={
          <PrivateRoute>
            <Layout>
              <AssignedStock />
            </Layout>
          </PrivateRoute>
        }
      />
    </Routes>
  );
};

export default DistributorRoutes;
