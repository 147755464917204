import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getLocationList,
  getSupplierLocationList,
} from "../../../redux/actions/locationAction";
import StockRow from "../../parlevel/modals/StockRow";
import Modal from "react-bootstrap/Modal";
import { replenishStock } from "../../../redux/actions/stockReplenishAction";

const ReplenishStockModal = ({
  requestId,
  isDistributor,
  minimumExpiry,
  modalShow,
  handleClose,
}) => {
  const dispatch = useDispatch();
  const { supplierLocationList, locationList } = useSelector(
    (state) => state.location
  );

  let initialState = [
    {
      locationId: "",
      assignedUnits: 0,
      expiry: "",
      lotNumber: "",
    },
  ];

  const [stock, setStock] = useState(initialState);

  const addRow = () => {
    const rowsInput = {
      locationId: "",
      assignedUnits: 0,
      expiry: "",
      lotNumber: "",
    };
    setStock([...stock, rowsInput]);
  };

  const handleDelete = (index) => {
    const rows = [...stock];
    rows.splice(index, 1);
    setStock(rows);
  };

  const handleChange = (index, e) => {
    const { name, value } = e.target;
    const rowsInput = [...stock];
    rowsInput[index][name] = value;
    setStock(rowsInput);
  };

  const handleSubmit = () => {
    dispatch(replenishStock(requestId, stock, minimumExpiry));
  };

  useEffect(() => {
    isDistributor
      ? dispatch(getLocationList())
      : dispatch(getSupplierLocationList());
  }, [dispatch, isDistributor]);
  return (
    <Modal
      size="lg"
      show={modalShow}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
    >
      <div className="card card-custom">
        <div className="card-header border-0">
          <div className="card-title">
            <div className="card-label">
              Add Location
              <small>Choose location below from list</small>
            </div>
          </div>
          <div className="card-toolbar">
            <button className="btn btn-link font-weight-500" onClick={addRow}>
              + Add More Location
            </button>
          </div>
        </div>
        <div className="card-body">
          <StockRow
            stock={stock}
            handleChange={handleChange}
            handleDelete={handleDelete}
            locationList={isDistributor ? locationList : supplierLocationList}
          />
        </div>
        <div className="card-footer border-0 text-right">
          <button
            className="btn btn-outline-dark btn-pill px-8"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            className="btn btn-gradient btn-pill px-8"
            data-dismiss="modal"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ReplenishStockModal;
