import { SUPPLIER_TYPES } from "../actions/supplierAction";

const initialState = {
  total: 0,
  suppliers: [],
  supplierList: [],
};

const supplierReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUPPLIER_TYPES.GET_SUPPLIERS:
      return {
        ...state,
        total: action.payload.total,
        suppliers: action.payload.suppliers,
      };

    case SUPPLIER_TYPES.LIST_SUPPLIERS:
      return {
        ...state,
        supplierList: action.payload,
      };

    case SUPPLIER_TYPES.CREATE_SUPPLIER:
      return {
        ...state,
        total: state.total + 1,
        suppliers: [action.payload, ...state.suppliers],
      };
    case SUPPLIER_TYPES.EDIT_SUPPLIER:
      return {
        ...state,
        suppliers: state.suppliers.map((supplier) => {
          if (supplier._id === action.payload._id) return action.payload;
          return supplier;
        }),
      };
    case SUPPLIER_TYPES.DELETE_SUPPLIER:
      return {
        ...state,
        total: state.total - 1,
        suppliers: state.suppliers.filter(
          (supplier) => supplier._id !== action.payload
        ),
      };
    default:
      return state;
  }
};

export default supplierReducer;
