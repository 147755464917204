import axios from "axios";

export const BASE_URL = "https://rds.healthprocanada.com";
//export const BASE_URL = "http://178.128.232.232:5005";
//export const BASE_URL = "http://localhost:5000";

export const getDataAPI = async (url, token) => {
  const res = await axios.get(`${BASE_URL}/api/${url}`, {
    headers: { Authorization: token },
  });
  return res;
};

export const postDataAPI = async (url, post, token) => {
  const res = await axios.post(`${BASE_URL}/api/${url}`, post, {
    headers: { Authorization: token },
  });
  return res;
};

export const putDataAPI = async (url, post, token) => {
  const res = await axios.put(`${BASE_URL}/api/${url}`, post, {
    headers: { Authorization: token },
  });
  return res;
};

export const patchDataAPI = async (url, post, token) => {
  const res = await axios.patch(`${BASE_URL}/api/${url}`, post, {
    headers: { Authorization: token },
  });
  return res;
};

export const deleteDataAPI = async (url, token) => {
  const res = await axios.delete(`${BASE_URL}/api/${url}`, {
    headers: { Authorization: token },
  });
  return res;
};

export const formDataPostAPI = async (url, formData, token) => {
  const res = await axios.post(`${BASE_URL}/api/${url}`, formData, {
    headers: { Authorization: token, ContentType: "multipart/form-data" },
  });
  return res;
};

export const formDataPatchAPI = async (url, formData, token) => {
  const res = await axios.patch(`${BASE_URL}/api/${url}`, formData, {
    headers: { Authorization: token, ContentType: "multipart/form-data" },
  });
  return res;
};
