import {
  deleteDataAPI,
  formDataPatchAPI,
  formDataPostAPI,
  getDataAPI,
} from "../../utils/fetchData";
import TYPES from "../constants";

export const PRODUCT_TYPES = {
  CREATE_PRODUCT: "CREATE_PRODUCT",
  GET_PRODUCTS: "GET_PRODUCTS",
  GET_PRODUCT: "GET_PRODUCT",
  EDIT_PRODUCT: "EDIT_PRODUCT",
  DELETE_PRODUCT: "DELETE_PRODUCT",
};

export const getProducts =
  (search, sort, limit, skip) => async (dispatch, getState) => {
    try {
      const { usertype, token } = getState().auth;
      dispatch({ type: TYPES.ALERT, payload: { loading: true } });
      const res = await getDataAPI(
        `${usertype}/products?limit=${limit}&skip=${skip}&search=${search}&sort=${sort}`,
        token
      );

      if (res.data.success) {
        dispatch({
          type: PRODUCT_TYPES.GET_PRODUCTS,
          payload: {
            products: res.data.data.products,
            total: res.data.data.total,
          },
        });
        dispatch({ type: TYPES.ALERT, payload: { loading: false } });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: TYPES.ALERT,
        payload: {
          error: err.response.data.message,
        },
      });
    }
  };

export const createProduct = (payload) => async (dispatch, getState) => {
  try {
    const { usertype, token } = getState().auth;

    const {
      image,
      name,
      brand,
      contractNumber,
      productId,
      price,
      minimumOrderSize,
      supplierId,
    } = payload;
    if (
      !image ||
      !name ||
      !brand ||
      !contractNumber ||
      !productId ||
      !price ||
      !minimumOrderSize ||
      !supplierId
    ) {
      dispatch({
        type: TYPES.ALERT,
        payload: {
          error: "Please fill all required fields",
        },
      });
      return;
    }

    const formData = new FormData();
    formData.append("image", payload.image);
    formData.append("name", payload.name);
    formData.append("brand", payload.brand);
    formData.append("contractNumber", payload.contractNumber);
    formData.append("productId", payload.productId);
    formData.append("price", payload.price);
    formData.append("minimumOrderSize", payload.minimumOrderSize);
    formData.append("supplierId", payload.supplierId);

    dispatch({ type: TYPES.ALERT, payload: { loading: true } });
    const res = await formDataPostAPI(`${usertype}/product`, formData, token);

    if (res.data.success) {
      dispatch({
        type: PRODUCT_TYPES.CREATE_PRODUCT,
        payload: res.data.data,
      });
      dispatch({ type: TYPES.ALERT, payload: { loading: false } });
      dispatch({
        type: TYPES.ALERT,
        payload: { success: res.data.message },
      });
    }
    return Promise.resolve();
  } catch (err) {
    console.log(err);
    dispatch({
      type: TYPES.ALERT,
      payload: {
        error: err.response.data.message,
      },
    });
    return Promise.reject();
  }
};

export const deleteProduct = (id) => async (dispatch, getState) => {
  try {
    const { usertype, token } = getState().auth;

    dispatch({ type: TYPES.ALERT, payload: { loading: true } });
    const res = await deleteDataAPI(`${usertype}/product/${id}`, token);

    if (res.data.success) {
      dispatch({
        type: PRODUCT_TYPES.DELETE_PRODUCT,
        payload: id,
      });
      dispatch({ type: TYPES.ALERT, payload: { loading: false } });
      dispatch({
        type: TYPES.ALERT,
        payload: { success: res.data.message },
      });
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: TYPES.ALERT,
      payload: {
        error: err.response.data.message,
      },
    });
  }
};

export const editProduct = (payload) => async (dispatch, getState) => {
  try {
    const { usertype, token } = getState().auth;

    dispatch({ type: TYPES.ALERT, payload: { loading: true } });
    const formData = new FormData();
    formData.append("image", payload.image);
    formData.append("name", payload.name);
    formData.append("brand", payload.brand);
    formData.append("contractNumber", payload.contractNumber);
    formData.append("price", payload.price);
    formData.append("minimumOrderSize", payload.minimumOrderSize);
    formData.append("productId", payload.productId);
    formData.append("supplierId", payload.supplierId);

    const res = await formDataPatchAPI(
      `${usertype}/product/${payload.id}`,
      formData,
      token
    );

    if (res.data.success) {
      dispatch({
        type: PRODUCT_TYPES.EDIT_PRODUCT,
        payload: {
          id: payload.id,
          product: res.data.data,
        },
      });
      dispatch({ type: TYPES.ALERT, payload: { loading: false } });
      dispatch({
        type: TYPES.ALERT,
        payload: { success: res.data.message },
      });
    }
    return Promise.resolve();
  } catch (err) {
    console.log(err);
    dispatch({
      type: TYPES.ALERT,
      payload: {
        error: err.response.data.message,
      },
    });
    return Promise.reject();
  }
};
