import React from "react";
import { BASE_URL } from "../../utils/fetchData";
import moment from "moment";

const SingleOrderExpiringHealthpro = ({ order }) => {
  let {
    orderId,
    isApproved,
    status,
    lotNumber,
    unitsExpiry: expiry,
    Member: {
      name: memberName,
      email: memberEmail,
      profilePic: memberProfilePic,
    },
    Supplier: {
      name: supplierName,
      address: supplierAddress,
      profilePic: supplierProfilePic,
    },
    Location: { line1, line2, postalCode },
    Stock: {
      Product: { name: productName, brand, image: productImage },
    },
  } = order;

  expiry = moment(expiry).format("MMM DD, YYYY");
  return (
    <tr>
      <td>{orderId}</td>
      <td>
        <div className="d-flex align-items-start">
          <div className="symbol symbol-40 bg-light mr-2">
            <img
              src={
                supplierProfilePic
                  ? `${BASE_URL}/${supplierProfilePic}`
                  : "assets/media/users/default.jpg"
              }
              alt=""
              className="img-fluid"
            />
          </div>
          <div>
            <div className="font-weight-boldest font-size-lg color-primary">
              {supplierName}
            </div>
            <div className="color-primary">{supplierAddress}</div>
          </div>
        </div>
      </td>
      <td>
        <div className="d-flex align-items-start">
          <div className="symbol symbol-40 bg-light mr-2">
            <img
              src={
                productImage
                  ? `${BASE_URL}/${productImage}`
                  : "assets/img/med-1.png"
              }
              alt=""
              className="img-fluid"
            />
          </div>
          <div>
            <div className="font-weight-boldest font-size-md color-primary">
              {productName}
            </div>
            <div className="color-secondary">{brand}</div>
          </div>
        </div>
      </td>
      <td>
        <div className="d-flex align-items-start">
          <div className="symbol symbol-40 bg-light mr-2">
            <img
              src={
                memberProfilePic
                  ? `${BASE_URL}/${memberProfilePic}`
                  : "assets/media/users/default.jpg"
              }
              alt=""
              className="img-fluid"
            />
          </div>
          <div>
            <div className="font-weight-boldest font-size-lg color-primary">
              {memberName}
            </div>
            <div className="color-secondary">{memberEmail}</div>
          </div>
        </div>
      </td>
      <td>{lotNumber}</td>
      <td>{expiry}</td>
      <td>{`${line1}, ${line2}, ${postalCode}`}</td>
      {!isApproved && (
        <td>
          <span className="badge rounded-pill badge-warning">
            Waiting for member approval
          </span>
        </td>
      )}
      {isApproved && status === "CREATED" && (
        <td>
          <span className="badge rounded-pill badge-primary">
            Approved my member
          </span>
        </td>
      )}
      {isApproved && status !== "CREATED" && (
        <td>
          <span className="badge rounded-pill badge-success">{status}</span>
        </td>
      )}
    </tr>
  );
};

export default SingleOrderExpiringHealthpro;
