import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../components/common/Pagination";
import { getLocations } from "../../redux/actions/locationAction";
import { BASE_URL } from "../../utils/fetchData";

const Location = () => {
  const dispatch = useDispatch();
  const { total, locations } = useSelector((state) => state.location);

  const [searchTerm, setSearchTerm] = useState("");
  const [sort, setSort] = useState("newest");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    if (searchTerm === "")
      dispatch(getLocations("", sort, rowsPerPage, page * rowsPerPage));
  }, [dispatch, searchTerm, sort, page, rowsPerPage]);

  useEffect(() => {
    const getData = setTimeout(() => {
      if (searchTerm !== "")
        dispatch(
          getLocations(searchTerm, sort, rowsPerPage, page * rowsPerPage)
        );
    }, 1000);

    return () => clearTimeout(getData);
  }, [dispatch, searchTerm, sort, page, rowsPerPage]);

  return (
    <div className="d-flex flex-column-fluid">
      {/* <!--begin::Container--> */}
      <div className="container">
        {/* <!--begin::Dashboard--> */}
        <div className="card card-custom border-radius-18px">
          <div className="card-header border-0">
            <div className="card-title">
              <div className="card-label">
                Locations{" "}
                <small>
                  Total {total} {total === 1 ? "Location" : "Locations"}{" "}
                </small>
              </div>
            </div>
            <div className="card-toolbar">
              <div className="form-group m-0 mr-2">
                <input
                  type="text"
                  className="form-control form-control-solid pl-4"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              <div className="mr-2">
                <select
                  className="form-control form-control-solid"
                  onChange={(e) => setSort(e.target.value)}
                >
                  <option value="newest">Newest</option>
                  <option value="oldest">Oldest</option>
                  <option value="name">By Name</option>
                </select>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <table className="table">
                <thead className="thead-light">
                  <tr>
                    <th>Location Name</th>
                    <th>Address</th>
                    <th>Comment</th>
                    <th>Supplier/Distributor</th>
                  </tr>
                </thead>
                <tbody>
                  {locations.map((location) => {
                    const {
                      name: locationName,
                      line1,
                      line2,
                      postalCode,
                      comment,
                      supplierId,
                      distributorId,
                      Supplier,
                      Distributor,
                    } = location;

                    return (
                      <tr key={location._id}>
                        <td>{locationName}</td>
                        <td>{`${line1}, ${line2}, ${postalCode}`}</td>
                        <td>{comment}</td>
                        <td>
                          <div className="d-flex align-items-start">
                            <div className="symbol symbol-40 bg-light mr-2">
                              <img
                                src={
                                  supplierId && Supplier.profilePic
                                    ? `${BASE_URL}/${Supplier.profilePic}`
                                    : distributorId && Distributor.profilePic
                                    ? `${BASE_URL}/${Distributor.profilePic}`
                                    : "assets/media/users/default.jpg"
                                }
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                            <div>
                              <div className="font-weight-boldest font-size-lg color-primary">
                                {supplierId ? Supplier.name : Distributor.name}
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <Pagination
              count={total}
              page={page}
              rowsPerPage={rowsPerPage}
              setPage={setPage}
              setRowsPerPage={setRowsPerPage}
            />
          </div>
        </div>
      </div>
      {/* <!--end::Container--> */}
    </div>
  );
};

export default Location;
