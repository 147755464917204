import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../components/common/Pagination";
import SingleOrderSupplier from "../../components/order/SingleOrderSupplier";

import { getOrders } from "../../redux/actions/orderAction";

const Order = () => {
  const dispatch = useDispatch();
  const { total, orders } = useSelector((state) => state.order);

  const [searchTerm, setSearchTerm] = useState("");
  const [sort, setSort] = useState("newest");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    if (searchTerm === "")
      dispatch(getOrders("", sort, rowsPerPage, page * rowsPerPage));
  }, [dispatch, searchTerm, sort, page, rowsPerPage]);

  useEffect(() => {
    const getData = setTimeout(() => {
      if (searchTerm !== "")
        dispatch(getOrders(searchTerm, sort, rowsPerPage, page * rowsPerPage));
    }, 1000);

    return () => clearTimeout(getData);
  }, [dispatch, searchTerm, sort, page, rowsPerPage]);

  return (
    <div className="d-flex flex-column-fluid">
      {/* <!--begin::Container--> */}
      <div className="container-fluid">
        {/* <!--begin::Dashboard--> */}
        {/* <!--begin::Row--> */}
        <div className="row mt-3">
          <div className="col-lg-12">
            <div className="card card-custom border-radius-18px">
              <div className="card-header border-0">
                <div className="card-title">
                  <div className="card-label">
                    Total {total} {total === 1 ? "Order" : "Orders"}{" "}
                  </div>
                </div>
                <div className="card-toolbar">
                  <div className="form-group m-0 mr-2">
                    <input
                      type="text"
                      className="form-control form-control-solid pl-4"
                      placeholder="Search..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                  <div>
                    <select
                      className="form-control form-control-solid"
                      onChange={(e) => setSort(e.target.value)}
                    >
                      <option value="newest">Newest</option>
                      <option value="oldest">Oldest</option>
                      <option value="name">By Name</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <table className="table">
                  <thead className="thead-light">
                    <tr>
                      <th>Items</th>
                      <th>Lot No.</th>
                      <th>Expiry Date</th>
                      <th>Location</th>
                      <th>Units</th>
                      <th width="150px">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orders.map((order) => {
                      return (
                        <SingleOrderSupplier order={order} key={order._id} />
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <Pagination
                count={total}
                page={page}
                rowsPerPage={rowsPerPage}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
              />
              {/* <div className="card-footer border-0">
                <div className="d-flex align-items-center justify-content-end gap-30px">
                  <div className="color-primary">
                    <b>1 Stock</b> ready to Ship
                  </div>
                  <div className="separator separator-solid separator-ver"></div>
                  <div>
                    <button className="btn btn-gradient btn-pill px-8">Ship</button>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        {/* <!--end::Dashboard--> */}
      </div>
      {/* <!--end::Container--> */}
    </div>
  );
};

export default Order;
