import {
  getDataAPI,
  deleteDataAPI,
  formDataPostAPI,
  formDataPatchAPI,
} from "../../utils/fetchData";
import TYPES from "../constants";

export const MEMBER_TYPES = {
  CREATE_MEMBER: "CREATE_MEMBER",
  EDIT_MEMBER: "EDIT_MEMBER",
  GET_MEMBERS: "GET_MEMBERS",
  GET_MEMBER: "GET_MEMBER",
  GET_MEMBER_NAMES: "GET_MEMBER_NAMES",
  DELETE_MEMBER: "DELETE_MEMBER",
};

export const getMembers =
  (search, sort, limit, skip) => async (dispatch, getState) => {
    try {
      const { usertype, token } = getState().auth;
      dispatch({ type: TYPES.ALERT, payload: { loading: true } });
      const res = await getDataAPI(
        `${usertype}/members?search=${search}&sort=${sort}&limit=${limit}&skip=${skip}`,
        token
      );

      if (res.data.success) {
        dispatch({
          type: MEMBER_TYPES.GET_MEMBERS,
          payload: {
            members: res.data.data.members,
            total: res.data.data.total,
          },
        });
        dispatch({ type: TYPES.ALERT, payload: { loading: false } });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: TYPES.ALERT,
        payload: {
          error: err.response.data.message,
        },
      });
    }
  };

export const getMemberNames = () => async (dispatch, getState) => {
  try {
    const { usertype, token } = getState().auth;
    dispatch({ type: TYPES.ALERT, payload: { loading: true } });
    const res = await getDataAPI(`${usertype}/memberList`, token);

    if (res.data.success) {
      console.log(res.data);
      dispatch({
        type: MEMBER_TYPES.GET_MEMBER_NAMES,
        payload: res.data.data,
      });
      dispatch({ type: TYPES.ALERT, payload: { loading: false } });
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: TYPES.ALERT,
      payload: {
        error: err.response.data.message,
      },
    });
  }
};

export const createMember = (payload) => async (dispatch, getState) => {
  try {
    if (
      !payload.image ||
      !payload.name ||
      !payload.email ||
      !payload.phone ||
      !payload.address
    ) {
      dispatch({
        type: TYPES.ALERT,
        payload: {
          error: "Please fill required fields",
        },
      });
      return;
    }
    const { usertype, token } = getState().auth;

    const formData = new FormData();
    formData.append("profilePic", payload.image);
    formData.append("name", payload.name);
    formData.append("email", payload.email);
    formData.append("phone", payload.phone);
    formData.append("address", payload.address);
    formData.append("expiryLotPreferences", payload.expiryLotPreferences);

    dispatch({ type: TYPES.ALERT, payload: { loading: true } });
    const res = await formDataPostAPI(
      `${usertype}/member/register`,
      formData,
      token
    );

    if (res.data.success) {
      dispatch({
        type: MEMBER_TYPES.CREATE_MEMBER,
        payload: res.data.data,
      });
      dispatch({ type: TYPES.ALERT, payload: { loading: false } });
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: TYPES.ALERT,
      payload: {
        error: err.response.data.message,
      },
    });
  }
};

export const editMember = (payload) => async (dispatch, getState) => {
  try {
    const { usertype, token } = getState().auth;

    const formData = new FormData();
    formData.append("profilePic", payload.image);
    formData.append("name", payload.name);
    formData.append("email", payload.email);
    formData.append("phone", payload.phone);
    formData.append("address", payload.address);
    formData.append("isActive", payload.isActive);

    dispatch({ type: TYPES.ALERT, payload: { loading: true } });
    const res = await formDataPatchAPI(
      `${usertype}/member/${payload.id}`,
      formData,
      token
    );

    console.log(res.data.data);
    if (res.data.success) {
      dispatch({
        type: MEMBER_TYPES.EDIT_MEMBER,
        payload: res.data.data,
      });
      dispatch({ type: TYPES.ALERT, payload: { loading: false } });
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: TYPES.ALERT,
      payload: {
        error: err.response.data.message,
      },
    });
  }
};

export const deleteMember = (id) => async (dispatch, getState) => {
  try {
    const { usertype, token } = getState().auth;
    dispatch({ type: TYPES.ALERT, payload: { loading: true } });
    const res = await deleteDataAPI(`${usertype}/member/${id}`, token);

    console.log(res.data.data);
    if (res.data.success) {
      dispatch({
        type: MEMBER_TYPES.DELETE_MEMBER,
        payload: id,
      });
      dispatch({ type: TYPES.ALERT, payload: { loading: false } });
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: TYPES.ALERT,
      payload: {
        error: err.response.data.message,
      },
    });
  }
};
