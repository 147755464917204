import {
  deleteDataAPI,
  getDataAPI,
  patchDataAPI,
  postDataAPI,
} from "../../utils/fetchData";
import TYPES from "../constants";

export const LOCATION_TYPES = {
  CREATE_LOCATION: "CREATE_LOCATION",
  LIST_LOCATIONS: "LIST_LOCATIONS",
  LIST_SUPPLIER_LOCATIONS: "LIST_SUPPLIER_LOCATIONS",
  EDIT_LOCATION: "EDIT_LOCATION",
  DELETE_LOCATION: "DELETE_LOCATION",
  GET_LOCATIONS: "GET_LOCATIONS",
  GET_LOCATION: "GET_LOCATION",
  APPROVE_LOCATION: "APPROVE_LOCATION",
  DENY_LOCATION: "DENY_LOCATION",
};

export const getLocations =
  (search, sort, limit, skip) => async (dispatch, getState) => {
    try {
      const { usertype, token } = getState().auth;
      dispatch({ type: TYPES.ALERT, payload: { loading: true } });
      const res = await getDataAPI(
        `${usertype}/locations?limit=${limit}&skip=${skip}&search=${search}&sort=${sort}`,
        token
      );

      if (res.data.success) {
        dispatch({
          type: LOCATION_TYPES.GET_LOCATIONS,
          payload: {
            locations: res.data.data.locations,
            total: res.data.data.total,
          },
        });
        dispatch({ type: TYPES.ALERT, payload: { loading: false } });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: TYPES.ALERT,
        payload: {
          error: err.response.data.message,
        },
      });
    }
  };

export const getLocationList = () => async (dispatch, getState) => {
  try {
    const { usertype, token } = getState().auth;
    dispatch({ type: TYPES.ALERT, payload: { loading: true } });
    const res = await getDataAPI(`${usertype}/locationlist`, token);

    if (res.data.success) {
      dispatch({
        type: LOCATION_TYPES.LIST_LOCATIONS,
        payload: {
          locations: res.data.data,
        },
      });
      dispatch({ type: TYPES.ALERT, payload: { loading: false } });
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: TYPES.ALERT,
      payload: {
        error: err.response.data.message,
      },
    });
  }
};

export const getSupplierLocationList = () => async (dispatch, getState) => {
  try {
    const { usertype, token } = getState().auth;
    dispatch({ type: TYPES.ALERT, payload: { loading: true } });
    const res = await getDataAPI(`${usertype}/locations/supplier`, token);

    if (res.data.success) {
      dispatch({
        type: LOCATION_TYPES.LIST_SUPPLIER_LOCATIONS,
        payload: {
          locations: res.data.data,
        },
      });
      dispatch({ type: TYPES.ALERT, payload: { loading: false } });
      return Promise.resolve();
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: TYPES.ALERT,
      payload: {
        error: err.response.data.message,
      },
    });
    return Promise.reject();
  }
};

export const addLocation = (payload) => async (dispatch, getState) => {
  try {
    if (
      !payload.name ||
      !payload.line1 ||
      !payload.line2 ||
      !payload.postalCode
    ) {
      dispatch({
        type: TYPES.ALERT,
        payload: {
          error: "Please fill required fields",
        },
      });
      return;
    }
    const { usertype, token } = getState().auth;
    dispatch({ type: TYPES.ALERT, payload: { loading: true } });
    const res = await postDataAPI(`${usertype}/location`, payload, token);

    if (res.data.success) {
      dispatch({
        type: LOCATION_TYPES.CREATE_LOCATION,
        payload: res.data.data,
      });
      dispatch({ type: TYPES.ALERT, payload: { loading: false } });
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: TYPES.ALERT,
      payload: {
        error: err.response.data.message,
      },
    });
  }
};

export const editLocation = (payload) => async (dispatch, getState) => {
  try {
    console.log(payload);
    if (
      !payload.name ||
      !payload.line1 ||
      !payload.line2 ||
      !payload.postalCode
    ) {
      dispatch({
        type: TYPES.ALERT,
        payload: {
          error: "Please fill required fields",
        },
      });
      return;
    }
    const { usertype, token } = getState().auth;
    dispatch({ type: TYPES.ALERT, payload: { loading: true } });
    const res = await patchDataAPI(
      `${usertype}/location/${payload.id}`,
      payload,
      token
    );

    if (res.data.success) {
      dispatch({
        type: LOCATION_TYPES.EDIT_LOCATION,
        payload: res.data.data,
      });
      dispatch({ type: TYPES.ALERT, payload: { loading: false } });
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: TYPES.ALERT,
      payload: {
        error: err.response.data.message,
      },
    });
  }
};

export const deleteLocation = (payload) => async (dispatch, getState) => {
  try {
    const { usertype, token } = getState().auth;
    dispatch({ type: TYPES.ALERT, payload: { loading: true } });
    const res = await deleteDataAPI(`${usertype}/location/${payload}`, token);

    if (res.data.success) {
      dispatch({
        type: LOCATION_TYPES.DELETE_LOCATION,
        payload,
      });
      dispatch({ type: TYPES.ALERT, payload: { loading: false } });
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: TYPES.ALERT,
      payload: {
        error: err.response.data.message,
      },
    });
  }
};

export const getLocation = (payload) => async (dispatch, getState) => {
  try {
    const { usertype, token } = getState().auth;
    dispatch({ type: TYPES.ALERT, payload: { loading: true } });
    const res = await getDataAPI(`${usertype}/location/${payload}`, token);

    if (res.data.success) {
      dispatch({
        type: LOCATION_TYPES.GET_LOCATION,
        payload: res.data.data,
      });
      dispatch({ type: TYPES.ALERT, payload: { loading: false } });
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: TYPES.ALERT,
      payload: {
        error: err.response.data.message,
      },
    });
  }
};

export const approveLocation = (payload) => async (dispatch, getState) => {
  try {
    const { usertype, token } = getState().auth;
    dispatch({ type: TYPES.ALERT, payload: { loading: true } });
    const res = await patchDataAPI(
      `${usertype}/location/${payload}/approve`,
      {},
      token
    );

    if (res.data.success) {
      dispatch({
        type: LOCATION_TYPES.APPROVE_LOCATION,
        payload: res.data.data,
      });
      dispatch({ type: TYPES.ALERT, payload: { loading: false } });
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: TYPES.ALERT,
      payload: {
        error: err.response.data.message,
      },
    });
  }
};

export const denyLocation = (payload) => async (dispatch, getState) => {
  try {
    const { usertype, token } = getState().auth;
    dispatch({ type: TYPES.ALERT, payload: { loading: true } });
    const res = await patchDataAPI(
      `${usertype}/location/${payload}/deny`,
      {},
      token
    );

    if (res.data.success) {
      dispatch({
        type: LOCATION_TYPES.DENY_LOCATION,
        payload: res.data.data,
      });
      dispatch({ type: TYPES.ALERT, payload: { loading: false } });
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: TYPES.ALERT,
      payload: {
        error: err.response.data.message,
      },
    });
  }
};
