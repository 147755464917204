import { getDataAPI, patchDataAPI, postDataAPI } from "../../utils/fetchData";
import TYPES from "../constants";
import { STOCK_SUPPLIER_TYPES } from "./stockSupplierAction";

export const STOCK_TYPES = {
  GET_STOCK: "GET_STOCK",
  GET_SINGLE_STOCK: "GET_SINGLE_STOCK",
  GET_STOCK_REQUEST: "GET_STOCK_REQUEST",
  GET_STOCK_REQUESTS: "GET_STOCK_REQUESTS",
  GET_STOCK_TRANSFER_REQUEST: "GET_STOCK_TRANSFER_REQUEST",
  APPROVE_SUGGESTED_STOCKS: "APPROVE_SUGGESTED_STOCKS",
  UPDATE_STOCK_STATUS: "UPDATE_STOCK_STATUS",
  APPROVE_STOCK_TRANSFER_REQUEST: "APPROVE_STOCK_TRANSFER_REQUEST",
  DENY_STOCK_TRANSFER_REQUEST: "DENY_STOCK_TRANSFER_REQUEST",
  UPDATE_STOCK_REQUEST: "UPDATE_STOCK_REQUEST",
  UPDATE_STOCK: "UPDATE_STOCK",
  GET_ASSIGN_STOCK_UNIT: "GET_ASSIGN_STOCK_UNIT",
  APPROVE_ASSIGN_STOCK_UNIT: "APPROVE_ASSIGN_STOCK_UNIT",
  DENY_ASSIGN_STOCK_UNIT: "DENY_ASSIGN_STOCK_UNIT",
  ADD_COMMENT: "ADD_COMMENT",
  UPDATE_STOCK_REQUEST_STATUS: "UPDATE_STOCK_REQUEST_STATUS",
};

export const getStock =
  (search, sort, limit, skip) => async (dispatch, getState) => {
    try {
      const { usertype, token } = getState().auth;
      dispatch({ type: TYPES.ALERT, payload: { loading: true } });
      const res = await getDataAPI(
        `${usertype}/stocks?limit=${limit}&skip=${skip}&search=${search}&sort=${sort}`,
        token
      );
      console.log(res.data);

      if (res.data.success) {
        dispatch({
          type: STOCK_TYPES.GET_STOCK,
          payload: {
            stocks: res.data.data.stocks,
            total: res.data.data.total,
          },
        });
        dispatch({ type: TYPES.ALERT, payload: { loading: false } });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: TYPES.ALERT,
        payload: {
          error: err.response.data.message,
        },
      });
    }
  };

export const getStockRequests =
  (search, sort, limit, skip) => async (dispatch, getState) => {
    try {
      const { usertype, token } = getState().auth;
      dispatch({ type: TYPES.ALERT, payload: { loading: true } });
      const res = await getDataAPI(
        `${usertype}/stockrequests?limit=${limit}&skip=${skip}&search=${search}&sort=${sort}`,
        token
      );
      console.log(res.data);

      if (res.data.success) {
        dispatch({
          type: STOCK_TYPES.GET_STOCK_REQUESTS,
          payload: {
            stockRequests: res.data.data.stockRequests,
            total: res.data.data.total,
          },
        });
        dispatch({ type: TYPES.ALERT, payload: { loading: false } });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: TYPES.ALERT,
        payload: {
          error: err.response.data.message,
        },
      });
    }
  };

export const denyStockRequest =
  (stockRequestId) => async (dispatch, getState) => {
    try {
      const { usertype, token } = getState().auth;
      console.log(token);
      dispatch({ type: TYPES.ALERT, payload: { loading: true } });
      const res = await patchDataAPI(
        `${usertype}/stockrequest/${stockRequestId}/deny`,
        {},
        token
      );
      console.log(res.data);

      if (res.data.success) {
        dispatch({
          type: STOCK_TYPES.UPDATE_STOCK_REQUEST,
          payload: { stockRequestId, status: "DENIED" },
        });

        dispatch({
          type: STOCK_SUPPLIER_TYPES.UPDATE_STOCK_STATUS,
          payload: { stockRequestId, status: "DENIED" },
        });

        dispatch({ type: TYPES.ALERT, payload: { loading: false } });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: TYPES.ALERT,
        payload: {
          error: err.response.data.message,
        },
      });
    }
  };

export const approveStockRequest =
  (stockRequestId) => async (dispatch, getState) => {
    try {
      const { usertype, token } = getState().auth;

      const res = await patchDataAPI(
        `${usertype}/stockrequest/${stockRequestId}/approve`,
        {},
        token
      );
      if (res.data.success) {
        dispatch({
          type: STOCK_TYPES.UPDATE_STOCK_REQUEST,
          payload: { stockRequestId, status: "APPROVED" },
        });
        dispatch({
          type: STOCK_SUPPLIER_TYPES.UPDATE_STOCK_STATUS,
          payload: { stockRequestId, status: "APPROVED" },
        });
        dispatch({ type: TYPES.ALERT, payload: { loading: false } });
        return Promise.resolve();
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: TYPES.ALERT,
        payload: {
          error: err.response.data.message,
        },
      });
      return Promise.reject();
    }
  };

export const assignStocksPermanent =
  (stockRequestId) => async (dispatch, getState) => {
    try {
      const { usertype, token } = getState().auth;

      const res = await patchDataAPI(
        `${usertype}/stockrequest/${stockRequestId}/paid`,
        {},
        token
      );
      if (res.data.success) {
        dispatch({
          type: STOCK_TYPES.UPDATE_STOCK_STATUS,
          payload: { stockRequestId, status: "ASSIGNED" },
        });
        dispatch({
          type: STOCK_TYPES.UPDATE_STOCK_REQUEST_STATUS,
          payload: "ASSIGNED",
        });
        dispatch({ type: TYPES.ALERT, payload: { loading: false } });
        return Promise.resolve();
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: TYPES.ALERT,
        payload: {
          error: err.response.data.message,
        },
      });
      return Promise.reject();
    }
  };

export const stockTransferRequest =
  (stockId, memberId, stockUnits) => async (dispatch, getState) => {
    try {
      if (stockUnits.length === 0) {
        dispatch({
          type: TYPES.ALERT,
          payload: {
            error: "No stock selected for transfer",
          },
        });
        return;
      }

      const { usertype, token } = getState().auth;

      dispatch({ type: TYPES.ALERT, payload: { loading: true } });
      const res = await postDataAPI(
        `${usertype}/stock/${stockId}/transfer/request`,
        { memberId, stockUnits },
        token
      );
      console.log(res.data);

      if (res.data.success) {
        dispatch({
          type: STOCK_TYPES.UPDATE_STOCK,
          payload: { stockId, stock: res.data.data },
        });
        dispatch({ type: TYPES.ALERT, payload: { loading: false } });
        dispatch({
          type: TYPES.ALERT,
          payload: {
            success: res.data.message,
          },
        });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: TYPES.ALERT,
        payload: {
          error: err.response.data.message,
        },
      });
    }
  };

export const approveStockTransferRequest =
  (stockTransferRequestId) => async (dispatch, getState) => {
    try {
      const { usertype, token } = getState().auth;
      console.log(token);
      dispatch({ type: TYPES.ALERT, payload: { loading: true } });
      const res = await patchDataAPI(
        `${usertype}/stocktransfer/${stockTransferRequestId}/approve`,
        {},
        token
      );
      console.log(res.data);

      if (res.data.success) {
        dispatch({ type: STOCK_TYPES.APPROVE_STOCK_TRANSFER_REQUEST });
        dispatch({ type: TYPES.ALERT, payload: { loading: false } });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: TYPES.ALERT,
        payload: {
          error: err.response.data.message,
        },
      });
    }
  };

export const denyStockTransferRequest =
  (stockTransferRequestId) => async (dispatch, getState) => {
    try {
      const { usertype, token } = getState().auth;

      dispatch({ type: TYPES.ALERT, payload: { loading: true } });
      const res = await patchDataAPI(
        `${usertype}/stocktransfer/${stockTransferRequestId}/deny`,
        {},
        token
      );
      console.log(res.data);

      if (res.data.success) {
        dispatch({ type: STOCK_TYPES.DENY_STOCK_TRANSFER_REQUEST });
        dispatch({ type: TYPES.ALERT, payload: { loading: false } });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: TYPES.ALERT,
        payload: {
          error: err.response.data.message,
        },
      });
    }
  };

export const getStockRequest =
  (stockRequestId) => async (dispatch, getState) => {
    try {
      const { usertype, token } = getState().auth;

      dispatch({ type: TYPES.ALERT, payload: { loading: true } });
      const res = await getDataAPI(
        `${usertype}/stockrequest/${stockRequestId}`,
        token
      );
      if (res.data.success) {
        dispatch({
          type: STOCK_TYPES.GET_STOCK_REQUEST,
          payload: res.data.data,
        });
        dispatch({ type: TYPES.ALERT, payload: { loading: false } });
        return Promise.resolve();
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: TYPES.ALERT,
        payload: {
          error: err.response.data.message,
        },
      });
    }
  };

export const getSingleStock = (stockId) => async (dispatch, getState) => {
  try {
    const { usertype, token } = getState().auth;

    dispatch({ type: TYPES.ALERT, payload: { loading: true } });
    const res = await getDataAPI(`${usertype}/stock/${stockId}`, token);
    if (res.data.success) {
      dispatch({
        type: STOCK_TYPES.GET_SINGLE_STOCK,
        payload: res.data.data,
      });
      dispatch({ type: TYPES.ALERT, payload: { loading: false } });
      return Promise.resolve();
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: TYPES.ALERT,
      payload: {
        error: err.response.data.message,
      },
    });
  }
};

export const getStockTransferRequest =
  (stockTransferRequestId) => async (dispatch, getState) => {
    try {
      const { usertype, token } = getState().auth;

      dispatch({ type: TYPES.ALERT, payload: { loading: true } });
      const res = await getDataAPI(
        `${usertype}/stocktransfer/${stockTransferRequestId}`,
        token
      );
      if (res.data.success) {
        dispatch({
          type: STOCK_TYPES.GET_STOCK_TRANSFER_REQUEST,
          payload: res.data.data,
        });
        dispatch({ type: TYPES.ALERT, payload: { loading: false } });
        return Promise.resolve();
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: TYPES.ALERT,
        payload: {
          error: err.response.data.message,
        },
      });
    }
  };

export const getAssignStockUnit =
  (assignStockUnitId) => async (dispatch, getState) => {
    try {
      const { usertype, token } = getState().auth;

      dispatch({ type: TYPES.ALERT, payload: { loading: true } });
      const res = await getDataAPI(
        `${usertype}/assignstock/${assignStockUnitId}`,
        token
      );
      if (res.data.success) {
        dispatch({
          type: STOCK_TYPES.GET_ASSIGN_STOCK_UNIT,
          payload: res.data.data,
        });
        dispatch({ type: TYPES.ALERT, payload: { loading: false } });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: TYPES.ALERT,
        payload: {
          error: err.response.data.message,
        },
      });
    }
  };

export const addComment =
  (stockRequestId, comment) => async (dispatch, getState) => {
    try {
      const { usertype, token } = getState().auth;

      dispatch({ type: TYPES.ALERT, payload: { loading: true } });
      const res = await postDataAPI(
        `${usertype}/stockrequest/${stockRequestId}/comment`,
        { comment },
        token
      );

      if (res.data.success) {
        dispatch({
          type: STOCK_TYPES.ADD_COMMENT,
          payload: {
            stockRequestId,
            comment: res.data.data,
          },
        });
        dispatch({ type: TYPES.ALERT, payload: { loading: false } });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: TYPES.ALERT,
        payload: {
          error: err.response.data.message,
        },
      });
    }
  };

export const approveStocks = (stockRequestId) => async (dispatch, getState) => {
  try {
    const { usertype, token } = getState().auth;

    dispatch({ type: TYPES.ALERT, payload: { loading: true } });
    const res = await patchDataAPI(
      `${usertype}/stockrequest/${stockRequestId}/approve`,
      {},
      token
    );

    if (res.data.success) {
      dispatch({
        type: STOCK_TYPES.APPROVE_SUGGESTED_STOCKS,
        payload: stockRequestId,
      });
      dispatch({
        type: STOCK_TYPES.UPDATE_STOCK_REQUEST_STATUS,
        payload: "ASSIGN_APPROVED",
      });
      dispatch({ type: TYPES.ALERT, payload: { loading: false } });
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: TYPES.ALERT,
      payload: {
        error: err.response.data.message,
      },
    });
  }
};
