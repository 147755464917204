import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddLocationModal from "../../components/location/modals/AddLocationModal";

import Pagination from "../../components/common/Pagination";
import {
  deleteLocation,
  getLocations,
} from "../../redux/actions/locationAction";
import EditLocationModal from "../../components/location/modals/EditLocationModal";
import SingleLocationDistributor from "../../components/location/SingleLocationDistributor";

const Location = () => {
  const dispatch = useDispatch();
  const { locations, total } = useSelector((state) => state.location);

  const [searchTerm, setSearchTerm] = useState("");
  const [sort, setSort] = useState("newest");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [addLocationModalShow, setAddLocationModalShow] = useState(false);
  const [editLocationModalShow, setEditLocationModalShow] = useState(false);
  const [modalInfo, setModalInfo] = useState({});

  const handleDeleteLocation = (id) => {
    dispatch(deleteLocation(id));
  };

  useEffect(() => {
    if (searchTerm === "")
      dispatch(getLocations("", sort, rowsPerPage, page * rowsPerPage));
  }, [dispatch, searchTerm, sort, page, rowsPerPage]);

  useEffect(() => {
    const getData = setTimeout(() => {
      if (searchTerm !== "")
        dispatch(
          getLocations(searchTerm, sort, rowsPerPage, page * rowsPerPage)
        );
    }, 1000);

    return () => clearTimeout(getData);
  }, [dispatch, searchTerm, sort, page, rowsPerPage]);

  const handleEditModalInfo = (location) => {
    setModalInfo({
      id: location._id,
      name: location.name,
      line1: location.line1,
      line2: location.line2,
      postalCode: location.postalCode,
      comment: location.comment,
    });
    setEditLocationModalShow(true);
  };

  return (
    <div className="d-flex flex-column-fluid">
      <div className="container-fluid">
        {/* <!--begin::Row--> */}
        <div className="row mt-3">
          <div className="col-lg-12">
            <div className="card card-custom border-radius-18px">
              <div className="card-header border-0">
                <div className="card-title">
                  <div className="card-label">
                    Locations{" "}
                    <small>
                      Total {total} {total === 1 ? "Location" : "Locations"}{" "}
                    </small>
                  </div>
                </div>
                <div className="card-toolbar">
                  <div className="form-group m-0 mr-2">
                    <input
                      type="text"
                      className="form-control form-control-solid pl-4"
                      placeholder="Search..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                  <div className="mr-2">
                    <select
                      className="form-control form-control-solid"
                      onChange={(e) => setSort(e.target.value)}
                    >
                      <option value="newest">Newest</option>
                      <option value="oldest">Oldest</option>
                      <option value="name">By Name</option>
                    </select>
                  </div>
                  <button
                    className="btn btn-blue mr-2"
                    onClick={() => setAddLocationModalShow(true)}
                  >
                    + Add Location
                  </button>
                </div>
              </div>
              <div className="card-body">
                <table className="table">
                  <thead className="thead-light">
                    <tr>
                      <th>Location Name</th>
                      <th>Location</th>
                      <th>Comment</th>
                      <th>Status</th>
                      <th width="150px">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {locations.map((location) => {
                      const {
                        _id,
                        name,
                        line1,
                        line2,
                        postalCode,
                        comment,
                        status,
                      } = location;
                      return (
                        <SingleLocationDistributor
                          key={_id}
                          name={name}
                          line1={line1}
                          line2={line2}
                          postalCode={postalCode}
                          comment={comment}
                          status={status}
                          handleShowModal={() => handleEditModalInfo(location)}
                          handleDeleteLocation={() => handleDeleteLocation(_id)}
                        />
                      );
                    })}
                  </tbody>
                </table>
                <Pagination
                  count={total}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  setPage={setPage}
                  setRowsPerPage={setRowsPerPage}
                />
              </div>
            </div>
          </div>
        </div>
        {/* <!--end::Dashboard--> */}
      </div>
      {/* <!--end::Container--> */}
      <AddLocationModal
        modalShow={addLocationModalShow}
        handleClose={() => setAddLocationModalShow(false)}
      />
      <EditLocationModal
        id={modalInfo.id}
        name={modalInfo.name}
        line1={modalInfo.line1}
        line2={modalInfo.line2}
        postalCode={modalInfo.postalCode}
        comment={modalInfo.comment}
        modalShow={editLocationModalShow}
        handleClose={() => setEditLocationModalShow(false)}
      />
    </div>
  );
};

export default Location;
