import React from "react";
import { Route, Routes } from "react-router-dom";
import Profile from "../components/common/Profile";
import AssignedStock from "../components/stock/AssignedStock";
import PrivateRoute from "../customRouter/PrivateRoute";
import TransferStockDetail from "../pages/common/TransferStockDetail";
import ExpiringLots from "../pages/healthpro/ExpiringLots";
import Location from "../pages/healthpro/Location";
import LocationDetail from "../pages/healthpro/LocationDetail";
import Order from "../pages/healthpro/Order";
import OrderDetail from "../pages/healthpro/OrderDetail";
import ParLevelDetail from "../pages/healthpro/ParLevelDetail";
import ProcessedOrderDetail from "../pages/healthpro/ProcessedOrderDetail";
import Product from "../pages/healthpro/Product";
import Query from "../pages/healthpro/Query";
import SupplyChainDetail from "../pages/healthpro/SupplyChainDetail";
import Layout from "../templates/HealthproLayout";

//pages
const Dashboard = React.lazy(() => import("../pages/healthpro/Dashboard"));
const Login = React.lazy(() => import("../pages/common/Login"));
const Member = React.lazy(() => import("../pages/healthpro/Member"));
const Supplier = React.lazy(() => import("../pages/healthpro/Supplier"));
const Distributor = React.lazy(() => import("../pages/healthpro/Distributor"));
const Notification = React.lazy(() =>
  import("../pages/healthpro/Notification")
);
const OTP = React.lazy(() => import("../pages/common/OTP"));

const HealthproRoutes = () => {
  return (
    <Routes>
      <Route exact path="/login" element={<Login />} />
      <Route exact path="/verify" element={<OTP />} />

      <Route
        exact
        path="/"
        element={
          <PrivateRoute>
            <Layout>
              <Dashboard />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/product"
        element={
          <PrivateRoute>
            <Layout>
              <Product />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/member"
        element={
          <PrivateRoute>
            <Layout>
              <Member />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/supplier"
        element={
          <PrivateRoute>
            <Layout>
              <Supplier />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/distributor"
        element={
          <PrivateRoute>
            <Layout>
              <Distributor />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/locations"
        element={
          <PrivateRoute>
            <Layout>
              <Location />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/notifications"
        element={
          <PrivateRoute>
            <Layout>
              <Notification />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/query"
        element={
          <PrivateRoute>
            <Layout>
              <Query />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/parlevel/:id"
        element={
          <PrivateRoute>
            <Layout>
              <ParLevelDetail />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/order"
        element={
          <PrivateRoute>
            <Layout>
              <Order />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/expiringlots"
        element={
          <PrivateRoute>
            <Layout>
              <ExpiringLots />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/me"
        element={
          <PrivateRoute>
            <Layout>
              <Profile />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/order/:id/new"
        element={
          <PrivateRoute>
            <Layout>
              <OrderDetail />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/order/:id/processed"
        element={
          <PrivateRoute>
            <Layout>
              <ProcessedOrderDetail />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/order/:id/completed"
        element={
          <PrivateRoute>
            <Layout>
              <ProcessedOrderDetail />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/location/:id"
        element={
          <PrivateRoute>
            <Layout>
              <LocationDetail />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/stocktransfer/:id"
        element={
          <PrivateRoute>
            <Layout>
              <TransferStockDetail />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/stock/:id"
        element={
          <PrivateRoute>
            <Layout>
              <AssignedStock />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/supplychain/:id"
        element={
          <PrivateRoute>
            <Layout>
              <SupplyChainDetail />
            </Layout>
          </PrivateRoute>
        }
      />
    </Routes>
  );
};

export default HealthproRoutes;
