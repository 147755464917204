import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  deleteSupplyChainInfo,
  editManufacturingFacilities,
  editMaterialSupplier,
  editWarehouses,
} from "../../redux/actions/supplychainAction";
import EditManufacturingModal from "./modals/EditManufacturingModal";
import EditMaterialModal from "./modals/EditMaterialModal";
import EditWarehouseModal from "./modals/EditWarehouseModal";

const Card = ({ item, type, supplyChainId }) => {
  const { name, line1, line2, postalCode, flag: flagValue } = item;
  const dispatch = useDispatch();

  const [flag, setFlag] = useState(null);

  useEffect(() => {
    setFlag(flagValue);
  }, [flagValue]);

  const [showMaterialSupplierModal, setShowMaterialSupplierModal] =
    useState(false);
  const [showManufacturingModal, setShowManufacturingModal] = useState(false);
  const [showWarehousesModal, setShowWarehousesModal] = useState(false);

  const handleEditClick = () => {
    if (type === "Material Supplier") setShowMaterialSupplierModal(true);
    else if (type === "Manufacturing Facility") setShowManufacturingModal(true);
    else if (type === "Warehouse") setShowWarehousesModal(true);
  };

  const handleDelete = () => {
    const payload = { id: item._id, type };
    dispatch(deleteSupplyChainInfo(payload));
  };

  const handleFlagChange = (changedFlag) => {
    setFlag(changedFlag);

    if (type === "Material Supplier")
      dispatch(
        editMaterialSupplier({
          id: item._id,
          flag: changedFlag,
          notify: true,
          supplyChainId,
        })
      );
    else if (type === "Manufacturing Facility")
      dispatch(
        editManufacturingFacilities({
          id: item._id,
          flag: changedFlag,
          notify: true,
          supplyChainId,
        })
      );
    else if (type === "Warehouse")
      dispatch(
        editWarehouses({
          id: item._id,
          flag: changedFlag,
          notify: true,
          supplyChainId,
        })
      );
  };

  return (
    <div className="card card-custom gutter-b bg-light">
      <div className="card-header align-items-center border-0 min-h-1px px-3">
        <div className="card-label">
          <p className="font-weight-600 m-0">{type}</p>
        </div>
        <div className="card-toolbar">
          <div className="dropdown dropdown-inline">
            <a
              href="#"
              className="btn btn-hover-light-primary btn-sm btn-icon"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="ki ki-bold-more-ver"></i>
            </a>
            <div className="dropdown-menu p-0 m-0 dropdown-menu-md dropdown-menu-right">
              {/* <!--begin::Navigation--> */}
              <ul className="navi navi-hover">
                <li className="navi-item">
                  <span className="navi-link">
                    <span className="navi-text" onClick={handleEditClick}>
                      Edit
                    </span>
                  </span>
                </li>
                <li className="navi-item">
                  <span className="navi-link">
                    <span className="navi-text" onClick={handleDelete}>
                      Delete
                    </span>
                  </span>
                </li>
              </ul>
              {/* <!--end::Navigation--> */}
            </div>
          </div>
        </div>
      </div>
      <div className="card-body p-3">
        <div className="d-flex align-items-center">
          <div className="dropdown mr-1">
            <button
              type="button"
              className="btn btn-outline-secondary dropdown-toggle border-0 p-0"
              id="dropdownMenuOffset"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              data-offset="10,20"
            >
              <i
                className={`fas fas fa-flag ${
                  flag === "GREEN"
                    ? "color-success"
                    : flag === "YELLOW"
                    ? "text-warning"
                    : flag === "RED"
                    ? "text-danger"
                    : null
                }`}
              ></i>
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuOffset">
              <span
                className="dropdown-item"
                onClick={() => handleFlagChange("GREEN")}
              >
                <i className="fas fas fa-flag color-success"></i>
              </span>
              <span
                className="dropdown-item"
                onClick={() => handleFlagChange("YELLOW")}
              >
                <i className="fas fas fa-flag text-warning"></i>
              </span>
              <span
                className="dropdown-item"
                onClick={() => handleFlagChange("RED")}
              >
                <i className="fas fas fa-flag text-danger"></i>
              </span>
            </div>
          </div>
          <div className="separator separator-solid separator-ver mx-3"></div>
          <div className="font-weight-600 font-size-18px color-primary">
            {name}
          </div>
        </div>
        <div className="color-primary font-weight-500">
          <span className="mr-2">
            <img src="assets/img/ic-location.svg" alt="" />
          </span>{" "}
          {`${line1}, ${postalCode}, ${line2}`}
        </div>
      </div>

      {type === "Material Supplier" && (
        <EditMaterialModal
          modalShow={showMaterialSupplierModal}
          handleClose={() => setShowMaterialSupplierModal(false)}
          item={item}
          type={type}
        />
      )}
      {type === "Manufacturing Facility" && (
        <EditManufacturingModal
          modalShow={showManufacturingModal}
          handleClose={() => setShowManufacturingModal(false)}
          item={item}
          type={type}
        />
      )}

      {type === "Warehouse" && (
        <EditWarehouseModal
          modalShow={showWarehousesModal}
          handleClose={() => setShowWarehousesModal(false)}
          item={item}
          type={type}
        />
      )}
    </div>
  );
};

export default Card;
