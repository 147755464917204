import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

//pages
const Login = React.lazy(() => import("../pages/common/Login"));
const OTP = React.lazy(() => import("../pages/common/OTP"));

const BasicRoutes = () => {
  return (
    <Routes>
      <Route exact path="/login" element={<Login />} />
      <Route exact path="/verify" element={<OTP />} />
      <Route exact path="/" element={<Login />} />
      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  );
};

export default BasicRoutes;
