import {
  getDataAPI,
  deleteDataAPI,
  formDataPostAPI,
  formDataPatchAPI,
} from "../../utils/fetchData";
import TYPES from "../constants";

export const SUPPLIER_TYPES = {
  CREATE_SUPPLIER: "CREATE_SUPPLIER",
  EDIT_SUPPLIER: "EDIT_SUPPLIER",
  GET_SUPPLIERS: "GET_SUPPLIERS",
  LIST_SUPPLIERS: "LIST_SUPPLIERS",
  DELETE_SUPPLIER: "DELETE_SUPPLIER",
};

export const getSuppliers =
  (search, sort, limit, skip) => async (dispatch, getState) => {
    try {
      const { usertype, token } = getState().auth;
      dispatch({ type: TYPES.ALERT, payload: { loading: true } });
      const res = await getDataAPI(
        `${usertype}/suppliers?search=${search}&sort=${sort}&limit=${limit}&skip=${skip}`,
        token
      );

      console.log(res);

      if (res.data.success) {
        dispatch({
          type: SUPPLIER_TYPES.GET_SUPPLIERS,
          payload: {
            suppliers: res.data.data.suppliers,
            total: res.data.data.total,
          },
        });
        dispatch({ type: TYPES.ALERT, payload: { loading: false } });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: TYPES.ALERT,
        payload: {
          error: err.response.data.message,
        },
      });
    }
  };

export const listSuppliers = () => async (dispatch, getState) => {
  try {
    const { usertype, token } = getState().auth;
    dispatch({ type: TYPES.ALERT, payload: { loading: true } });
    const res = await getDataAPI(`${usertype}/suppliers/all`, token);

    console.log(res);

    if (res.data.success) {
      dispatch({
        type: SUPPLIER_TYPES.LIST_SUPPLIERS,
        payload: res.data.data,
      });
      dispatch({ type: TYPES.ALERT, payload: { loading: false } });
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: TYPES.ALERT,
      payload: {
        error: err.response.data.message,
      },
    });
  }
};

export const createSupplier = (payload) => async (dispatch, getState) => {
  try {
    if (
      !payload.image ||
      !payload.name ||
      !payload.email ||
      !payload.phone ||
      !payload.address
    ) {
      dispatch({
        type: TYPES.ALERT,
        payload: {
          error: "Please fill required fields",
        },
      });
      return;
    }
    const { usertype, token } = getState().auth;

    const formData = new FormData();
    formData.append("profilePic", payload.image);
    formData.append("name", payload.name);
    formData.append("email", payload.email);
    formData.append("phone", payload.phone);
    formData.append("address", payload.address);

    dispatch({ type: TYPES.ALERT, payload: { loading: true } });
    const res = await formDataPostAPI(
      `${usertype}/supplier/register`,
      formData,
      token
    );

    if (res.data.success) {
      dispatch({
        type: SUPPLIER_TYPES.CREATE_SUPPLIER,
        payload: res.data.data,
      });
      dispatch({ type: TYPES.ALERT, payload: { loading: false } });
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: TYPES.ALERT,
      payload: {
        error: err.response.data.message,
      },
    });
  }
};

export const editSupplier = (payload) => async (dispatch, getState) => {
  try {
    const { usertype, token } = getState().auth;

    const formData = new FormData();
    formData.append("profilePic", payload.image);
    formData.append("name", payload.name);
    formData.append("email", payload.email);
    formData.append("phone", payload.phone);
    formData.append("address", payload.address);
    formData.append("isActive", payload.isActive);

    dispatch({ type: TYPES.ALERT, payload: { loading: true } });
    const res = await formDataPatchAPI(
      `${usertype}/supplier/${payload.id}`,
      formData,
      token
    );

    console.log(res.data.data);
    if (res.data.success) {
      dispatch({
        type: SUPPLIER_TYPES.EDIT_SUPPLIER,
        payload: res.data.data,
      });
      dispatch({ type: TYPES.ALERT, payload: { loading: false } });
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: TYPES.ALERT,
      payload: {
        error: err.response.data.message,
      },
    });
  }
};

export const deleteSupplier = (id) => async (dispatch, getState) => {
  try {
    const { usertype, token } = getState().auth;
    dispatch({ type: TYPES.ALERT, payload: { loading: true } });
    const res = await deleteDataAPI(`${usertype}/supplier/${id}`, token);

    console.log(res.data.data);
    if (res.data.success) {
      dispatch({
        type: SUPPLIER_TYPES.DELETE_SUPPLIER,
        payload: id,
      });
      dispatch({ type: TYPES.ALERT, payload: { loading: false } });
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: TYPES.ALERT,
      payload: {
        error: err.response.data.message,
      },
    });
  }
};
