import { getDataAPI, patchDataAPI, postDataAPI } from "../../utils/fetchData";
import TYPES from "../constants";

export const SUPPLYCHAIN_TYPES = {
  GET_SUPPLYCHAIN: "GET_SUPPLYCHAIN",
  GET_SINGLE_SUPPLYCHAIN: "GET_SINGLE_SUPPLYCHAIN",
  APPROVE_SINGLE_SUPPLYCHAIN: "APPROVE_SINGLE_SUPPLYCHAIN",
  DENY_SINGLE_SUPPLYCHAIN: "DENY_SINGLE_SUPPLYCHAIN",
  ADD_MATERIAL_SUPPLIER: "ADD_MATERIAL_SUPPLIER",
  ADD_MANUFACTURING_FACILITIES: "ADD_MANUFACTURING_FACILITIES",
  ADD_WAREHOUSES: "ADD_WAREHOUSES",
  EDIT_MATERIAL_SUPPLIER: "EDIT_MATERIAL_SUPPLIER",
  EDIT_MANUFACTURING_FACILITIES: "EDIT_MANUFACTURING_FACILITIES",
  EDIT_WAREHOUSES: "EDIT_WAREHOUSES",
  DELETE_SUPPLYCHAININFO: "DELETE_SUPPLYCHAININFO",
  SUBMIT_SUPPLYCHAIN: "SUBMIT_SUPPLYCHAIN",
};

export const getSupplyChain =
  (search, sort, limit, skip) => async (dispatch, getState) => {
    try {
      const { usertype, token } = getState().auth;
      dispatch({ type: TYPES.ALERT, payload: { loading: true } });
      const res = await getDataAPI(
        `${usertype}/supplychain?limit=${limit}&skip=${skip}&search=${search}&sort=${sort}`,
        token
      );

      if (res.data.success) {
        dispatch({
          type: SUPPLYCHAIN_TYPES.GET_SUPPLYCHAIN,
          payload: {
            products: res.data.data.supplychain,
            total: res.data.data.total,
          },
        });
        dispatch({ type: TYPES.ALERT, payload: { loading: false } });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: TYPES.ALERT,
        payload: {
          error: err.response.data.message,
        },
      });
    }
  };

export const getSingleSupplyChain = (id) => async (dispatch, getState) => {
  try {
    const { usertype, token } = getState().auth;
    dispatch({ type: TYPES.ALERT, payload: { loading: true } });

    const res = await getDataAPI(`${usertype}/supplychain/${id}`, token);

    if (res.data.success) {
      dispatch({
        type: SUPPLYCHAIN_TYPES.GET_SINGLE_SUPPLYCHAIN,
        payload: res.data.data,
      });
      dispatch({ type: TYPES.ALERT, payload: { loading: false } });
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: TYPES.ALERT,
      payload: {
        error: err.response.data.message,
      },
    });
  }
};

export const approveSupplyChain = (id) => async (dispatch, getState) => {
  try {
    const { usertype, token } = getState().auth;
    dispatch({ type: TYPES.ALERT, payload: { loading: true } });

    const res = await patchDataAPI(
      `${usertype}/supplychain/${id}/approve`,
      {},
      token
    );

    if (res.data.success) {
      dispatch({
        type: SUPPLYCHAIN_TYPES.APPROVE_SINGLE_SUPPLYCHAIN,
      });
      dispatch({ type: TYPES.ALERT, payload: { loading: false } });
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: TYPES.ALERT,
      payload: {
        error: err.response.data.message,
      },
    });
  }
};

export const denySupplyChain = (id) => async (dispatch, getState) => {
  try {
    const { usertype, token } = getState().auth;
    dispatch({ type: TYPES.ALERT, payload: { loading: true } });

    const res = await patchDataAPI(
      `${usertype}/supplychain/${id}/deny`,
      {},
      token
    );

    if (res.data.success) {
      dispatch({
        type: SUPPLYCHAIN_TYPES.DENY_SINGLE_SUPPLYCHAIN,
      });
      dispatch({ type: TYPES.ALERT, payload: { loading: false } });
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: TYPES.ALERT,
      payload: {
        error: err.response.data.message,
      },
    });
  }
};

export const AddMaterialSupplier =
  (productId, payload) => async (dispatch, getState) => {
    try {
      if (
        !payload.name ||
        !payload.line1 ||
        !payload.line2 ||
        !payload.postalCode
      ) {
        dispatch({
          type: TYPES.ALERT,
          payload: {
            error: "Please fill required fields",
          },
        });
        return;
      }
      const { usertype, token } = getState().auth;
      dispatch({ type: TYPES.ALERT, payload: { loading: true } });
      payload.type = "MATERIAL_SUPPLIER";
      const res = await postDataAPI(
        `${usertype}/product/${productId}/supplychain`,
        payload,
        token
      );

      if (res.data.success) {
        console.log(res.data.data.supplyChainInfo);
        dispatch({
          type: SUPPLYCHAIN_TYPES.ADD_MATERIAL_SUPPLIER,
          payload: {
            productId,
            supplyChainInfo: res.data.data.supplyChainInfo,
            supplyChainId: res.data.data.supplyChainId,
          },
        });
        dispatch({ type: TYPES.ALERT, payload: { loading: false } });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: TYPES.ALERT,
        payload: {
          error: err.response.data.message,
        },
      });
    }
  };

export const AddManfacturingFacilities =
  (productId, payload) => async (dispatch, getState) => {
    try {
      if (
        !payload.name ||
        !payload.line1 ||
        !payload.line2 ||
        !payload.postalCode
      ) {
        dispatch({
          type: TYPES.ALERT,
          payload: {
            error: "Please fill required fields",
          },
        });
        return;
      }
      const { usertype, token } = getState().auth;
      dispatch({ type: TYPES.ALERT, payload: { loading: true } });
      payload.type = "MANUFACTURING_FACILITIES";
      const res = await postDataAPI(
        `${usertype}/product/${productId}/supplychain`,
        payload,
        token
      );

      if (res.data.success) {
        dispatch({
          type: SUPPLYCHAIN_TYPES.ADD_MANUFACTURING_FACILITIES,
          payload: {
            productId,
            supplyChainInfo: res.data.data.supplyChainInfo,
            supplyChainId: res.data.data.supplyChainId,
          },
        });
        dispatch({ type: TYPES.ALERT, payload: { loading: false } });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: TYPES.ALERT,
        payload: {
          error: err.response.data.message,
        },
      });
    }
  };

export const AddWarehouses =
  (productId, payload) => async (dispatch, getState) => {
    try {
      if (
        !payload.name ||
        !payload.line1 ||
        !payload.line2 ||
        !payload.postalCode
      ) {
        dispatch({
          type: TYPES.ALERT,
          payload: {
            error: "Please fill required fields",
          },
        });
        return;
      }
      const { usertype, token } = getState().auth;
      dispatch({ type: TYPES.ALERT, payload: { loading: true } });
      payload.type = "WAREHOUSES";
      const res = await postDataAPI(
        `${usertype}/product/${productId}/supplychain`,
        payload,
        token
      );

      if (res.data.success) {
        dispatch({
          type: SUPPLYCHAIN_TYPES.ADD_WAREHOUSES,
          payload: {
            productId,
            supplyChainInfo: res.data.data.supplyChainInfo,
            supplyChainId: res.data.data.supplyChainId,
          },
        });
        dispatch({ type: TYPES.ALERT, payload: { loading: false } });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: TYPES.ALERT,
        payload: {
          error: err.response.data.message,
        },
      });
    }
  };

export const editMaterialSupplier = (payload) => async (dispatch, getState) => {
  try {
    if (
      !payload.name ||
      !payload.line1 ||
      !payload.line2 ||
      !payload.postalCode
    ) {
      dispatch({
        type: TYPES.ALERT,
        payload: {
          error: "Please fill required fields",
        },
      });
      return;
    }
    const { usertype, token } = getState().auth;
    dispatch({ type: TYPES.ALERT, payload: { loading: true } });
    payload.type = "Material Supplier";
    const res = await patchDataAPI(
      `${usertype}/supplychain/${payload.id}`,
      payload,
      token
    );

    if (res.data.success) {
      dispatch({
        type: SUPPLYCHAIN_TYPES.EDIT_MATERIAL_SUPPLIER,
        payload: {
          id: payload.id,
          type: payload.type,
          data: res.data.data,
        },
      });
      dispatch({ type: TYPES.ALERT, payload: { loading: false } });
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: TYPES.ALERT,
      payload: {
        error: err.response.data.message,
      },
    });
  }
};

export const editManufacturingFacilities =
  (payload) => async (dispatch, getState) => {
    try {
      if (
        !payload.name ||
        !payload.line1 ||
        !payload.line2 ||
        !payload.postalCode
      ) {
        dispatch({
          type: TYPES.ALERT,
          payload: {
            error: "Please fill required fields",
          },
        });
        return;
      }
      const { usertype, token } = getState().auth;
      dispatch({ type: TYPES.ALERT, payload: { loading: true } });
      payload.type = "Manufacturing Facility";
      console.log(payload);
      const res = await patchDataAPI(
        `${usertype}/supplychain/${payload.id}`,
        payload,
        token
      );

      if (res.data.success) {
        dispatch({
          type: SUPPLYCHAIN_TYPES.EDIT_MANUFACTURING_FACILITIES,
          payload: {
            id: payload.id,
            type: payload.type,
            data: res.data.data,
          },
        });
        dispatch({ type: TYPES.ALERT, payload: { loading: false } });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: TYPES.ALERT,
        payload: {
          error: err.response.data.message,
        },
      });
    }
  };

export const editWarehouses = (payload) => async (dispatch, getState) => {
  try {
    if (
      !payload.name ||
      !payload.line1 ||
      !payload.line2 ||
      !payload.postalCode
    ) {
      dispatch({
        type: TYPES.ALERT,
        payload: {
          error: "Please fill required fields",
        },
      });
      return;
    }
    const { usertype, token } = getState().auth;
    dispatch({ type: TYPES.ALERT, payload: { loading: true } });
    payload.type = "Warehouse";
    const res = await patchDataAPI(
      `${usertype}/supplychain/${payload.id}`,
      payload,
      token
    );

    if (res.data.success) {
      dispatch({
        type: SUPPLYCHAIN_TYPES.EDIT_WAREHOUSES,
        payload: {
          id: payload.id,
          type: payload.type,
          data: res.data.data,
        },
      });
      dispatch({ type: TYPES.ALERT, payload: { loading: false } });
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: TYPES.ALERT,
      payload: {
        error: err.response.data.message,
      },
    });
  }
};

export const deleteSupplyChainInfo =
  (payload) => async (dispatch, getState) => {
    try {
      const { usertype, token } = getState().auth;
      dispatch({ type: TYPES.ALERT, payload: { loading: true } });

      const res = await patchDataAPI(
        `${usertype}/supplychain/${payload.id}/delete`,
        { type: payload.type },
        token
      );

      if (res.data.success) {
        dispatch({
          type: SUPPLYCHAIN_TYPES.DELETE_SUPPLYCHAININFO,
          payload: {
            id: payload.id,
            type: payload.type,
          },
        });
        dispatch({ type: TYPES.ALERT, payload: { loading: false } });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: TYPES.ALERT,
        payload: {
          error: err.response.data.message,
        },
      });
    }
  };

export const submitSupplyChain = (id) => async (dispatch, getState) => {
  try {
    const { usertype, token } = getState().auth;
    dispatch({ type: TYPES.ALERT, payload: { loading: true } });

    const res = await postDataAPI(`${usertype}/supplychain/${id}`, {}, token);

    if (res.data.success) {
      dispatch({
        type: SUPPLYCHAIN_TYPES.SUBMIT_SUPPLYCHAIN,
        payload: id,
      });
      dispatch({
        type: TYPES.ALERT,
        payload: { success: res.data.message },
      });
      dispatch({ type: TYPES.ALERT, payload: { loading: false } });
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: TYPES.ALERT,
      payload: {
        error: err.response.data.message,
      },
    });
  }
};
