import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../components/common/Pagination";
import SingleStock from "../../components/parlevel/SingleStock";
import { getStocks } from "../../redux/actions/stockSupplierAction";

const ParLevel = () => {
  const dispatch = useDispatch();

  const { stocks, total } = useSelector((state) => state.stockSupplier);

  const [searchTerm, setSearchTerm] = useState("");
  const [sort, setSort] = useState("newest");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    if (searchTerm === "")
      dispatch(getStocks("", sort, rowsPerPage, page * rowsPerPage));
  }, [dispatch, searchTerm, sort, page, rowsPerPage]);

  useEffect(() => {
    const getData = setTimeout(() => {
      if (searchTerm !== "")
        dispatch(getStocks(searchTerm, sort, rowsPerPage, page * rowsPerPage));
    }, 1000);

    return () => clearTimeout(getData);
  }, [dispatch, searchTerm, sort, page, rowsPerPage]);

  return (
    <div className="d-flex flex-column-fluid">
      {/* <!--begin::Container--> */}
      <div className="container-fluid">
        {/* <!--begin::Dashboard-->
								<!--begin::Row--> */}
        <div className="row mt-3">
          <div className="col-lg-12">
            <div className="card card-custom border-radius-18px">
              <div className="card-header border-0">
                <div className="card-title">
                  <div className="card-label">
                    Products{" "}
                    <small>
                      Total {total} {total === 1 ? "Stock" : "Stocks"}{" "}
                    </small>
                  </div>
                </div>
                <div className="card-toolbar">
                  <div className="form-group m-0 mr-2">
                    <input
                      type="text"
                      className="form-control form-control-solid pl-4"
                      placeholder="Search..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                  <div className="mr-2">
                    <select
                      className="form-control form-control-solid"
                      onChange={(e) => setSort(e.target.value)}
                    >
                      <option value="newest">Newest</option>
                      <option value="oldest">Oldest</option>
                      <option value="name">By Name</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="card-body">
                {stocks.map((stock) => {
                  return <SingleStock key={stock._id} stock={stock} />;
                })}
              </div>
              <Pagination
                count={total}
                page={page}
                rowsPerPage={rowsPerPage}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
              />
            </div>
          </div>
        </div>
        {/* <!--end::Dashboard--> */}
      </div>
      {/* <!--end::Container--> */}
    </div>
  );
};

export default ParLevel;
