import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import Loading from "../../components/alert/Loading";
import SingleSupplyChainInfo from "../../components/supplychain/SingleSupplyChainInfo";
import {
  approveSupplyChain,
  denySupplyChain,
  getSingleSupplyChain,
} from "../../redux/actions/supplychainAction";
import { BASE_URL } from "../../utils/fetchData";

const SupplyChainDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { supplychain } = useSelector((state) => state.supplychain);

  useEffect(() => {
    const getData = () => dispatch(getSingleSupplyChain(id));
    getData();

    return () => clearTimeout(getData);
  }, [dispatch, id]);

  const handleApprove = () => {
    dispatch(approveSupplyChain(id));
  };

  const handleDeny = () => {
    dispatch(denySupplyChain(id));
  };

  return (
    <>
      {Object.keys(supplychain).length === 0 && <Loading />}
      {Object.keys(supplychain).length > 0 && (
        <div className="d-flex flex-column-fluid">
          {/* <!--begin::Container--> */}
          <div className="container">
            <div className="card card-custom border-radius-18px">
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col" style={{ minWidth: "250px" }}>
                          Supplier Name
                        </th>
                        <th scope="col" style={{ minWidth: "250px" }}>
                          Drug
                        </th>
                        <th scope="col" style={{ minWidth: "250px" }}>
                          Material Supplier
                        </th>
                        <th scope="col" style={{ minWidth: "250px" }}>
                          Manufacturing Facilities
                        </th>
                        <th scope="col" style={{ minWidth: "250px" }}>
                          Warehouses/Distribution Centres
                        </th>
                        {supplychain.isApproved === false && (
                          <th scope="col" style={{ minWidth: "250px" }}>
                            Action
                          </th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="d-flex align-items-start">
                            <div className="symbol symbol-40 bg-light mr-2">
                              <img
                                src={
                                  supplychain.Product.Supplier.profilePic
                                    ? `${BASE_URL}/${supplychain.Product.Supplier.profilePic}`
                                    : "assets/media/users/default.jpg"
                                }
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                            <div>
                              <div className="font-weight-boldest font-size-lg color-primary">
                                {supplychain.Product.Supplier.name}
                              </div>
                              <div className="color-primary">
                                {supplychain.Product.Supplier.address}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-start">
                            <div className="symbol symbol-40 bg-light mr-2">
                              <img
                                src={
                                  supplychain.Product.image
                                    ? `${BASE_URL}/${supplychain.Product.image}`
                                    : "assets/img/med-1.png"
                                }
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                            <div>
                              <div className="font-weight-boldest font-size-lg color-primary">
                                {supplychain.Product.name}
                              </div>
                              <div className="color-primary">
                                {supplychain.Product.brand}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          {supplychain.MaterialSuppliers.map((item) => {
                            return (
                              <SingleSupplyChainInfo
                                key={item._id}
                                item={item}
                              />
                            );
                          })}
                        </td>
                        <td>
                          {supplychain.ManufacturingFacilities.map((item) => {
                            return (
                              <SingleSupplyChainInfo
                                key={item._id}
                                item={item}
                              />
                            );
                          })}
                        </td>
                        <td>
                          {supplychain.Warehouses.map((item) => {
                            return (
                              <SingleSupplyChainInfo
                                key={item._id}
                                item={item}
                              />
                            );
                          })}
                        </td>
                        {supplychain.isApproved === false && (
                          <td>
                            <button
                              className="btn btn-sm btn-danger"
                              onClick={handleDeny}
                            >
                              Deny
                            </button>{" "}
                            <button
                              className="btn btn-sm btn-blue"
                              onClick={handleApprove}
                            >
                              Approve
                            </button>
                          </td>
                        )}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {/* <!--end::Container--> */}
        </div>
      )}
    </>
  );
};

export default SupplyChainDetail;
