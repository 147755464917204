export const routes = [
  {
    key: 1,
    route: "/",
    text: "Dashboard",
    icon: "flaticon2-architecture-and-city",
  },
  {
    key: 2,
    route: "/product",
    text: "Products",
    icon: "flaticon2-layers-1",
  },
  {
    key: 3,
    route: "/supplier",
    text: "Suppliers",
    icon: "flaticon2-user-outline-symbol",
  },
  {
    key: 4,
    route: "/member",
    text: "Members",
    icon: "flaticon2-user-outline-symbol",
  },
  {
    key: 5,
    route: "/distributor",
    text: "Distributors",
    icon: "flaticon2-user-outline-symbol",
  },
  {
    key: 6,
    route: "/locations",
    text: "Locations",
    icon: "flaticon2-delivery-truck",
  },
  {
    key: 7,
    route: "/order",
    text: "Orders",
    icon: "flaticon2-list-2",
  },
  {
    key: 8,
    route: "/expiringlots",
    text: "Expiring Lots",
    icon: "flaticon2-delivery-truck",
  },
  {
    key: 9,
    route: "/notifications",
    text: "Notifications",
    icon: "flaticon2-bell-4",
  },
  {
    key: 10,
    route: "/query",
    text: "Queries",
    icon: "flaticon2-user-outline-symbol",
  },
];
