import { getDataAPI, patchDataAPI, postDataAPI } from "../../utils/fetchData";
import TYPES from "../constants";

export const SURESUPPLY_TYPES = {
  GET_SURE_SUPPLY: "GET_SURE_SUPPLY",
  ADD_STOCK_REQUEST: "ADD_STOCK_REQUEST",
  GET_STOCK_REQUEST: "GET_STOCK_REQUEST",
};

export const addSureSupply = (productIds) => async (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { usertype, token } = getState().auth;

      dispatch({ type: TYPES.ALERT, payload: { loading: true } });
      const res = await postDataAPI(
        `${usertype}/suresupply`,
        { productIds },
        token
      );

      if (res.data.success) {
        dispatch({ type: TYPES.ALERT, payload: { loading: false } });
        dispatch({
          type: TYPES.ALERT,
          payload: { success: res.data.data.message },
        });
        resolve(res.data);
      } else {
        reject();
      }
    } catch (err) {
      console.log(err);
      reject(err);
      dispatch({
        type: TYPES.ALERT,
        payload: {
          error: err.response.data.message,
        },
      });
    }
  });
};

export const getSureSupply =
  (search, sort, limit, skip) => async (dispatch, getState) => {
    try {
      const { usertype, token } = getState().auth;
      dispatch({ type: TYPES.ALERT, payload: { loading: true } });
      const res = await getDataAPI(
        `${usertype}/suresupply?search=${search}&sort=${sort}&limit=${limit}&skip=${skip}`,
        token
      );
      console.log(res.data);

      if (res.data.success) {
        dispatch({
          type: SURESUPPLY_TYPES.GET_SURE_SUPPLY,
          payload: {
            stock: res.data.data.stock,
            total: res.data.data.total,
          },
        });
        dispatch({ type: TYPES.ALERT, payload: { loading: false } });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: TYPES.ALERT,
        payload: {
          error: err.response.data.message,
        },
      });
    }
  };

export const increaseParLevel =
  (stockId, parLevel, newParLevel, minExpiry, requestedParLevel) =>
  async (dispatch, getState) => {
    try {
      const { usertype, token } = getState().auth;
      dispatch({ type: TYPES.ALERT, payload: { loading: true } });
      const res = await postDataAPI(
        `${usertype}/stock/increase`,
        { stockId, requestedParLevel: newParLevel, minimumExpiry: minExpiry },
        token
      );
      console.log(res.data);

      if (res.data.success) {
        dispatch({
          type: SURESUPPLY_TYPES.ADD_STOCK_REQUEST,
          payload: {
            stockId: res.data.data.stockId,
            requestedParLevel: res.data.data.requestedParLevel,
            requestedMinExpiry: res.data.data.minimumExpiry,
            requestId: res.data.data._id,
          },
        });
        dispatch({ type: TYPES.ALERT, payload: { loading: false } });
        dispatch({
          type: TYPES.ALERT,
          payload: { success: "Stock increase request generated" },
        });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: TYPES.ALERT,
        payload: {
          error: err.response.data.message,
        },
      });
    }
  };

export const decreaseParLevel =
  (stockId, newParLevel, minExpiry, requestedParLevel) =>
  async (dispatch, getState) => {
    try {
      const { usertype, token } = getState().auth;

      dispatch({ type: TYPES.ALERT, payload: { loading: true } });
      const res = await postDataAPI(
        `${usertype}/stock/decrease`,
        { stockId, requestedParLevel: newParLevel, minimumExpiry: minExpiry },
        token
      );
      console.log(res.data);

      if (res.data.success) {
        dispatch({
          type: SURESUPPLY_TYPES.ADD_STOCK_REQUEST,
          payload: {
            stockId: res.data.data.stockId,
            requestedParLevel: res.data.data.requestedParLevel,
            requestedMinExpiry: res.data.data.minimumExpiry,
          },
        });
        dispatch({ type: TYPES.ALERT, payload: { loading: false } });
        dispatch({
          type: TYPES.ALERT,
          payload: { success: "Stock decrease request generated" },
        });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: TYPES.ALERT,
        payload: {
          error: err.response.data.message,
        },
      });
    }
  };

export const updateParLevel =
  (requestId, newParLevel, minExpiry) => async (dispatch, getState) => {
    try {
      const { usertype, token } = getState().auth;
      dispatch({ type: TYPES.ALERT, payload: { loading: true } });
      const res = await patchDataAPI(
        `${usertype}/stockrequest/${requestId}`,
        { requestedParLevel: newParLevel, minimumExpiry: minExpiry },
        token
      );
      console.log(res.data);

      if (res.data.success) {
        dispatch({
          type: SURESUPPLY_TYPES.ADD_STOCK_REQUEST,
          payload: {
            stockId: res.data.data.stockId,
            requestedParLevel: res.data.data.requestedParLevel,
            requestedMinExpiry: res.data.data.minimumExpiry,
          },
        });
        dispatch({ type: TYPES.ALERT, payload: { loading: false } });
        dispatch({
          type: TYPES.ALERT,
          payload: { success: "Stock increase request updated" },
        });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: TYPES.ALERT,
        payload: {
          error: err.response.data.message,
        },
      });
    }
  };
