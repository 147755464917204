import { QUERY_TYPES } from "../actions/queryAction";

const initialState = {
  total: 0,
  queries: [],
};

const queryReducer = (state = initialState, action) => {
  switch (action.type) {
    case QUERY_TYPES.GET_QUERIES:
      return {
        ...state,
        total: action.payload.total,
        queries: action.payload.queries,
      };

    case QUERY_TYPES.DELETE_QUERY:
      return {
        ...state,
        total: state.total - 1,
        queries: state.queries.filter((query) => query._id !== action.payload),
      };
    default:
      return state;
  }
};

export default queryReducer;
