import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Pagination from "../../components/common/Pagination";
import SingleSupplierProduct from "../../components/product/SingleSupplierProduct";
import { getProducts } from "../../redux/actions/supplierProductAction";

const Product = () => {
  const dispatch = useDispatch();
  const { products, total } = useSelector((state) => state.supplierProduct);

  const [searchTerm, setSearchTerm] = useState("");
  const [sort, setSort] = useState("newest");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    if (searchTerm === "")
      dispatch(getProducts("", sort, rowsPerPage, page * rowsPerPage));
  }, [dispatch, searchTerm, sort, page, rowsPerPage]);

  useEffect(() => {
    const getData = setTimeout(() => {
      if (searchTerm !== "")
        dispatch(
          getProducts(searchTerm, sort, rowsPerPage, page * rowsPerPage)
        );
    }, 1000);

    return () => clearTimeout(getData);
  }, [dispatch, searchTerm, sort, page, rowsPerPage]);

  return (
    <div className="d-flex flex-column-fluid">
      {/* <!--begin::Container--> */}
      <div className="container-fluid">
        {/* <!--begin::Dashboard--> */}
        {/* <!--begin::Row--> */}
        <div className="row mt-3">
          <div className="col-lg-12">
            <div className="card card-custom border-radius-18px">
              <div className="card-header border-0">
                <div className="card-title">
                  <div className="card-label">
                    <small>
                      Total {total} {total === 1 ? "Product" : "Products"}
                    </small>
                  </div>
                </div>
                <div className="card-toolbar">
                  <div className="form-group m-0 mr-2">
                    <input
                      type="text"
                      className="form-control form-control-solid pl-4"
                      placeholder="Search..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                  <div>
                    <select
                      className="form-control form-control-solid"
                      onChange={(e) => setSort(e.target.value)}
                    >
                      <option value="newest">Newest</option>
                      <option value="oldest">Oldest</option>
                      <option value="name">By Name</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <table className="table">
                  <thead className="thead-light">
                    <tr>
                      <th>Items</th>
                      <th>Product ID</th>
                      <th>Contract Number</th>
                      <th>Price</th>
                      <th width="150px">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {products.map((product) => {
                      return (
                        <SingleSupplierProduct
                          key={product._id}
                          product={product}
                        />
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <Pagination
                count={total}
                page={page}
                rowsPerPage={rowsPerPage}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
              />
            </div>
          </div>
        </div>
        {/* <!--end::Dashboard--> */}
      </div>
      {/* <!--end::Container--> */}
    </div>
  );
};

export default Product;
