import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import { selectDistributors } from "../../../redux/actions/distributorAction";

const SelectDistributorModal = ({ distributors, modalShow, handleClose }) => {
  const dispatch = useDispatch();
  const [selectedDistributors, setSelectedDistributors] = useState(null);

  //populate distributors
  const options = [];
  distributors.forEach((distributor) => {
    options.push({ value: distributor._id, label: distributor.name });
  });

  const handleSelected = () => {
    if (selectedDistributors.length === 0) return;
    const payload = [];
    selectedDistributors.forEach((d) => payload.push(d.value));

    dispatch(selectDistributors(payload));
    handleClose();
    setSelectedDistributors(null);
  };

  return (
    <Modal
      size="md"
      show={modalShow}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
    >
      <div className="modal-content rounded-0">
        <div className="modal-header">
          <h5 className="modal-title">Select Distributor(s) </h5>
        </div>

        <div className="modal-body">
          <Select
            value={selectedDistributors}
            onChange={setSelectedDistributors}
            options={options}
            isMulti={true}
          />
        </div>
        <div className="modal-footer">
          <button className="btn btn-primary" onClick={handleSelected}>
            Select
          </button>
          <button className="btn btn-blue" onClick={handleClose}>
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default SelectDistributorModal;
