import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import { editMaterialSupplier } from "../../../redux/actions/supplychainAction";

const EditMaterialModal = ({ modalShow, handleClose, item, type }) => {
  const {
    _id: id,
    name: e_name,
    line1: e_line1,
    line2: e_line2,
    postalCode: e_postalCode,
  } = item;

  const dispatch = useDispatch();
  const [name, setName] = useState(e_name);
  const [line1, setLine1] = useState(e_line1);
  const [line2, setLine2] = useState(e_line2);
  const [postalCode, setPostalCode] = useState(e_postalCode);

  const handleSubmit = (e) => {
    e.preventDefault();

    const payload = {
      id,
      name,
      line1,
      line2,
      postalCode,
    };
    dispatch(editMaterialSupplier(payload));
    handleClose();
  };

  useEffect(() => {
    setName(e_name);
    setLine1(e_line1);
    setLine2(e_line2);
    setPostalCode(e_postalCode);
  }, [e_name, e_line1, e_line2, e_postalCode]);

  return (
    <Modal
      show={modalShow}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
    >
      <div className="card card-custom">
        <div className="card-header border-0">
          <div className="card-title">
            <div className="card-label">
              Edit Material Supplier
              <small>Edit below details</small>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="form-group">
            <input
              type="text"
              className="form-control form-control-rounded"
              placeholder="Material Supplier"
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control form-control-rounded"
              placeholder="Address Line 1"
              onChange={(e) => setLine1(e.target.value)}
              value={line1}
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control form-control-rounded"
              placeholder="Address Line 2"
              onChange={(e) => setLine2(e.target.value)}
              value={line2}
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control form-control-rounded"
              placeholder="Postal Code"
              onChange={(e) => setPostalCode(e.target.value)}
              value={postalCode}
            />
          </div>
        </div>
        <div className="card-footer border-0 text-right">
          <button
            className="btn btn-outline-dark btn-pill px-8"
            onClick={handleClose}
          >
            Cancel
          </button>{" "}
          <button
            className="btn btn-gradient btn-pill px-8"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default EditMaterialModal;
