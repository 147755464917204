import React from "react";
import { BASE_URL } from "../../utils/fetchData";

const SingleLocation = ({
  name,
  line1,
  line2,
  postalCode,
  comment,
  status,
  distributor,
  handleShowModal,
  handleDeleteLocation,
}) => {
  return (
    <tr>
      <td>
        {distributor && (
          <div className="d-flex align-items-start">
            <div className="symbol symbol-40 bg-light mr-2">
              <img
                src={
                  distributor.profilePic
                    ? `${BASE_URL}/${distributor.profilePic}`
                    : "/assets/media/users/default.jpg"
                }
                alt=""
                className="img-fluid"
              />
            </div>
            <div>
              <div className="font-weight-boldest font-size-lg color-primary">
                {distributor.name}
              </div>
              <div className="color-secondary">{distributor.email}</div>
            </div>
          </div>
        )}
      </td>
      <td>{name}</td>
      <td>{`${line1}, ${line2}, ${postalCode}`}</td>
      <td>{comment}</td>
      <td>
        <span
          className={`color-${status === "PENDING" ? "warning" : "success"}`}
        >
          {status === "PENDING" ? "Waiting for Approval" : "Active"}
        </span>
      </td>
      <td>
        {!distributor && (
          <>
            <button className="btn btn-sm btn-light btn-icon">
              <i
                className="flaticon2-rubbish-bin-delete-button color-primary"
                onClick={handleDeleteLocation}
              ></i>
            </button>
            <button
              className="btn btn-sm btn-light btn-icon"
              onClick={handleShowModal}
            >
              <i className="flaticon2-edit color-primary"></i>
            </button>
          </>
        )}
      </td>
    </tr>
  );
};

export default SingleLocation;
