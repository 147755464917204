import { DISTRIBUTOR_TYPES } from "../actions/distributorAction";

const initialState = {
  total: 0,
  distributors: [],
  distributorNames: [],
};

const distributorReducer = (state = initialState, action) => {
  switch (action.type) {
    case DISTRIBUTOR_TYPES.GET_DISTRIBUTORS:
      return {
        ...state,
        total: action.payload.total,
        distributors: action.payload.distributors,
      };
    case DISTRIBUTOR_TYPES.GET_DISTRIBUTOR_NAMES:
      return {
        ...state,
        distributorNames: action.payload,
      };
    case DISTRIBUTOR_TYPES.CREATE_DISTRIBUTOR:
      return {
        ...state,
        total: state.total + 1,
        distributors: [action.payload, ...state.distributors],
      };
    case DISTRIBUTOR_TYPES.EDIT_DISTRIBUTOR:
      return {
        ...state,
        distributors: state.distributors.map((distributor) => {
          if (distributor._id === action.payload._id) return action.payload;
          return distributor;
        }),
      };
    case DISTRIBUTOR_TYPES.DELETE_DISTRIBUTOR:
      return {
        ...state,
        total: state.total - 1,
        distributors: state.distributors.filter(
          (distributor) => distributor._id !== action.payload
        ),
      };
    default:
      return state;
  }
};

export default distributorReducer;
