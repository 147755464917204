import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { countUnseenNotifications } from "../../redux/actions/notifyAction";

const Sidebar = ({ routes }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { countUnseen } = useSelector((state) => state.notify);

  const [active, setActive] = useState(null);

  useEffect(() => {
    dispatch(countUnseenNotifications());
  }, [dispatch]);

  useEffect(() => {
    routes.map(({ key, route }) => {
      if (route === location.pathname) setActive(key);
    });
  }, [routes, location.pathname]);

  return (
    <div
      className="aside-menu-wrapper flex-column-fluid"
      id="kt_aside_menu_wrapper"
    >
      {/* <!--begin::Menu Container--> */}
      <div
        id="kt_aside_menu"
        className="aside-menu my-4 revampedSideBar"
        data-menu-vertical="1"
        data-menu-scroll="1"
        data-menu-dropdown-timeout="500"
      >
        {/* <!--begin::Menu Nav--> */}
        <ul className="menu-nav">
          {routes.map(({ key, route, text, icon }) => {
            return (
              <li
                key={key}
                className={`menu-item ${active === key && "menu-item-active"}`}
                // aria-haspopup="true"
                onClick={() => setActive(key)}
              >
                <Link to={route} className="menu-link">
                  <span className="menu-icon">
                    <i className={icon}></i>
                  </span>
                  <span className="menu-text">{text}</span>
                  {route === "/notifications" && (
                    <span className="menu-label">
                      {countUnseen > 0 && (
                        <span className="label label-rounded label-danger">
                          {countUnseen}
                        </span>
                      )}
                    </span>
                  )}
                </Link>
              </li>
            );
          })}
        </ul>
        {/* <!--end::Menu Nav--> */}
      </div>
    </div>
  );
};

export default Sidebar;
