import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createQuery } from "../../redux/actions/queryAction";

const Contact = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { name, email } = user;

  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(createQuery(subject, message)).then(() => {
      setSubject("");
      setMessage("");
    });
  };
  return (
    <div className="d-flex flex-column-fluid">
      {/* <!--begin::Container--> */}
      <div className="container">
        {/* <!--begin::Dashboard--> */}
        {/* <!--begin::Row--> */}
        <div className="row mt-3">
          <div className="col-lg-12">
            <div className="card card-custom border-radius-18px">
              <div className="card-header border-0">
                <div className="card-title">
                  <div className="card-label">
                    Send Us Email{" "}
                    <small>Looking to connect? We are here to help.</small>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-6">
                    <form action="" className="contactForm">
                      <div className="row">
                        <div className="col-6">
                          <div className="form-group">
                            <label htmlFor="">Name</label>
                            <input
                              type="text"
                              className="form-control form-control-solid"
                              placeholder="Enter Name"
                              value={name}
                              disabled={true}
                            />
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-group">
                            <label htmlFor="">Email</label>
                            <input
                              type="email"
                              className="form-control form-control-solid"
                              placeholder="Enter Email"
                              value={email}
                              disabled={true}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="">Subject</label>
                        <input
                          type="text"
                          className="form-control form-control-solid"
                          placeholder="Enter Subject"
                          value={subject}
                          onChange={(e) => setSubject(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="">Message</label>
                        <textarea
                          className="form-control form-control-solid"
                          placeholder="Enter Subject"
                          rows="5"
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                        ></textarea>
                      </div>
                      <div className="text-right">
                        <button
                          onClick={handleSubmit}
                          className="btn btn-gradient btn-pill px-8"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                  <div className="col-lg-6">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2895.848648274808!2d-79.685593!3d43.4637569!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b5cdb68a56bfb%3A0xde01eea44de9fbd0!2s360%20Oakville%20Pl%20Dr%20%23102%2C%20Oakville%2C%20ON%20L6H%206K8%2C%20Canada!5e0!3m2!1sen!2sin!4v1667990231718!5m2!1sen!2sin"
                      width="100%"
                      height="400"
                      style={{ border: "0" }}
                      allowFullScreen=""
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                </div>
                <div className="row mt-3 addressBar">
                  <div className="col-lg-4">
                    <div className="card card-custom card-light card-stretch">
                      <div className="card-body p-0">
                        <h5>Mailing Address</h5>
                        <p>
                          360 Oakville Place Drive, Suite #102, Oakville, ON,
                          L6H 6K8
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="card card-custom card-light card-stretch">
                      <div className="card-body p-0">
                        <h5>Telephone:</h5>
                        <p>905-568-3478</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="card card-custom card-light card-stretch">
                      <div className="card-body p-0">
                        <h5>Telephone:</h5>
                        <p>905-568-3478</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--end::Dashboard--> */}
      </div>
      {/* <!--end::Container--> */}
    </div>
  );
};

export default Contact;
