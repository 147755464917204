import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { BASE_URL } from "../../utils/fetchData";
import { logoutUser } from "../../redux/actions/authAction";
import { Link } from "react-router-dom";

const Topbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);

  const logout = () => {
    dispatch(logoutUser());
    navigate("/");
  };

  return (
    <div className="dropdown">
      <div
        className="topbar-item"
        data-toggle="dropdown"
        data-offset="10px,0px"
      >
        <div
          className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
          id="kt_quick_user_toggle"
        >
          <span className="symbol symbol-35 symbol-circle symbol-light-success">
            <img
              src={
                user.profilePic
                  ? `${BASE_URL}/${user.profilePic}`
                  : "assets/media/users/blank.png"
              }
              alt=""
            />
          </span>
        </div>
      </div>
      <div className="dropdown-menu dropdown-menu-sm">
        <ul className="navi">
          <li className="navi-item">
            <Link className="navi-link" to="/me">
              <span className="navi-icon">
                <i className="flaticon2-user-outline-symbol"></i>
              </span>
              <span className="navi-text">Profile</span>
            </Link>
          </li>
          <li className="navi-item" onClick={logout}>
            <span className="navi-link" href="#">
              <span className="navi-icon">
                <i className="flaticon-logout"></i>
              </span>
              <span className="navi-text">Logout</span>
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Topbar;
