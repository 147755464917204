import React, { useState } from "react";
import { BASE_URL } from "../../utils/fetchData";
import AssignedStockAtLocationModal from "./modals/AssignedStockAtLocationModal";

const SingleStockSupplierAggregate = ({ stock }) => {
  const {
    product: {
      productId,
      name: productName,
      brand: productBrand,
      image: productImage,
    },
    location: { name: locationName, line1, line2, postalCode },
    totalUnits,
    stockUnits,
  } = stock;

  const [showStockModal, setShowStockModal] = useState(false);

  return (
    <div className="card card-custom card-border productCard rounded-0 gutter-b">
      <div className="card-body p-3">
        <div className="row">
          <div className="col-lg-6">
            <div className="d-flex align-items-center flex-wrap">
              <div className="text-center mr-3">
                <div className="symbol symbol-120 bg-light">
                  <img
                    src={`${BASE_URL}/${productImage}`}
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <div className="color-secondary">Product Id: {productId}</div>
              </div>
              <div>
                <div className="productName">{productName.toUpperCase()}</div>
                <div className="productBrand">{productBrand}</div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="d-flex justify-content-between align-items-stretch h-100 flex-column">
              <div className="d-flex align-items-center flex-wrap">
                <div>
                  <div className="productName">{locationName}</div>
                  <div className="productBrand">{`${line1} ${line2} ${postalCode}`}</div>
                  <div className="color-success">
                    Total Stock: {totalUnits}{" "}
                    <span>
                      <i
                        className="fas fa-info-circle text-dark"
                        onClick={() => setShowStockModal(true)}
                      ></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- start::Modals--> */}
      <AssignedStockAtLocationModal
        units={stockUnits}
        modalShow={showStockModal}
        handleClose={() => setShowStockModal(false)}
      />
      {/* <!-- end::Modals--> */}
    </div>
  );
};

export default SingleStockSupplierAggregate;
