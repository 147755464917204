import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../components/common/Pagination";
import SingleNotificationCommon from "../../components/notification/SingleNotificationCommon";
import { getNotifications } from "../../redux/actions/notifyAction";

const Notification = () => {
  const dispatch = useDispatch();
  const { total, notifications } = useSelector((state) => state.notify);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    const getData = () => {
      dispatch(getNotifications("", "", rowsPerPage, page * rowsPerPage));
    };
    getData();

    return () => clearTimeout(getData);
  }, [dispatch, page, rowsPerPage]);

  return (
    <div className="d-flex flex-column-fluid">
      {/* <!--begin::Container--> */}
      <div className="container">
        {/* <!--begin::Dashboard-->
								<!--begin::Row--> */}
        <div className="row mt-3">
          <div className="col-lg-12">
            <div className="card card-custom border-radius-18px gutter-b">
              <div className="card-header border-0">
                <div className="card-title">
                  <div className="card-label">Notifications</div>
                </div>
              </div>
              <div className="card-body">
                {notifications.map((notification) => {
                  return (
                    <SingleNotificationCommon
                      key={notification._id}
                      notification={notification}
                    />
                  );
                })}
              </div>
              <Pagination
                count={total}
                page={page}
                rowsPerPage={rowsPerPage}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
              />
            </div>
          </div>
        </div>
        {/* <!--end::Dashboard--> */}
      </div>
      {/* <!--end::Container--> */}
    </div>
  );
};

export default Notification;
