export const routes = [
  {
    key: 1,
    route: "/",
    text: "Dashboard",
    icon: "flaticon2-architecture-and-city",
  },
  {
    key: 2,
    route: "/parlevel",
    text: "Par Levels",
    icon: "flaticon2-layers-1",
  },
  {
    key: 3,
    route: "/order",
    text: "Orders",
    icon: "flaticon2-list-2",
  },
  {
    key: 4,
    route: "/notifications",
    text: "Notifications",
    icon: "flaticon2-bell-4",
  },
  {
    key: 5,
    route: "/members/stock",
    text: "Member Stock",
    icon: "flaticon2-notepad",
  },
  {
    key: 6,
    route: "/stock",
    text: "Total Stock",
    icon: "flaticon2-notepad",
  },
  {
    key: 7,
    route: "/replenishment",
    text: "Replenishment Tasks",
    icon: "flaticon2-user-outline-symbol",
  },
  {
    key: 8,
    route: "/location",
    text: "Locations",
    icon: "flaticon2-delivery-truck",
  },
  {
    key: 9,
    route: "/product",
    text: "Products",
    icon: "flaticon2-delivery-truck",
  },
  {
    key: 10,
    route: "/supplychain",
    text: "Supply Chain",
    icon: "flaticon2-delivery-truck",
  },
  {
    key: 11,
    route: "/expiringlots",
    text: "Expiring Lots",
    icon: "flaticon2-delivery-truck",
  },
  {
    key: 12,
    route: "/contact",
    text: "Contact",
    icon: "flaticon2-mail",
  },
];
