import { MEMBER_TYPES } from "../actions/memberAction";

const initialState = {
  total: 0,
  members: [],
  names: [],
};

const memberReducer = (state = initialState, action) => {
  switch (action.type) {
    case MEMBER_TYPES.GET_MEMBERS:
      return {
        ...state,
        total: action.payload.total,
        members: action.payload.members,
      };

    case MEMBER_TYPES.GET_MEMBER_NAMES:
      return {
        ...state,
        names: action.payload,
      };
    case MEMBER_TYPES.CREATE_MEMBER:
      return {
        ...state,
        total: state.total + 1,
        members: [action.payload, ...state.members],
      };
    case MEMBER_TYPES.EDIT_MEMBER:
      return {
        ...state,
        members: state.members.map((member) => {
          if (member._id === action.payload._id) return action.payload;
          return member;
        }),
      };
    case MEMBER_TYPES.DELETE_MEMBER:
      return {
        ...state,
        total: state.total - 1,
        members: state.members.filter(
          (member) => member._id !== action.payload
        ),
      };
    default:
      return state;
  }
};

export default memberReducer;
