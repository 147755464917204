import React from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { addLocation } from "../../../redux/actions/locationAction";

const AddLocationModal = ({ modalShow, handleClose }) => {
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      name: "",
      line1: "",
      line2: "",
      postalCode: "",
      comment: "",
    },

    validationSchema: Yup.object({
      name: Yup.string().required("Location name required"),
      line1: Yup.string().required("Address line 1 required"),
      line2: Yup.string().required("Address line 2 required"),
      postalCode: Yup.string()
        .required("Postal Code required")
        .test("regex validation", function (value) {
          console.log(value);
          const { path, createError } = this;
          const result = value.match(
            /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i
          );
          return (
            result || createError({ path, message: "Invalid Postal Code" })
          );
        }),
    }),

    onSubmit: (values, { resetForm }) => {
      const payload = {
        name: values.name,
        line1: values.line1,
        line2: values.line2,
        postalCode: values.postalCode,
        comment: values.comment,
      };

      dispatch(addLocation(payload));
      resetForm({ values: "" });
      handleClose();
    },
  });

  return (
    <Modal
      size="md"
      show={modalShow}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
    >
      <form
        className="card card-custom"
        onSubmit={formik.handleSubmit}
        id="kt_add_location_form"
      >
        <div className="card-header border-0">
          <div className="card-title">
            <div className="card-label">
              Add Location
              <small>Enter below details to add</small>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="form-group">
            <input
              type="text"
              className="form-control form-control-rounded"
              placeholder="Location Name"
              name="name"
              onChange={formik.handleChange}
              defaultValue={formik.values.name}
            />
            <span style={{ color: "red" }}> {formik.errors.name}</span>
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control form-control-rounded"
              placeholder="Address Line 1"
              name="line1"
              onChange={formik.handleChange}
              defaultValue={formik.values.line1}
            />
            <span style={{ color: "red" }}> {formik.errors.line1} </span>
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control form-control-rounded"
              placeholder="Address Line 2"
              name="line2"
              onChange={formik.handleChange}
              defaultValue={formik.values.line2}
            />
            <span style={{ color: "red" }}> {formik.errors.line2} </span>
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control form-control-rounded"
              placeholder="Postal Code"
              name="postalCode"
              onChange={formik.handleChange}
              defaultValue={formik.values.postalCode}
            />
            <span style={{ color: "red" }}> {formik.errors.postalCode} </span>
          </div>
          <div className="form-group">
            <textarea
              name="comment"
              id=""
              cols="30"
              rows="10"
              className="form-control form-control-rounded"
              placeholder="Comment"
              onChange={formik.handleChange}
              defaultValue={formik.values.comment}
            ></textarea>
          </div>
        </div>
        <div className="card-footer border-0 text-right">
          <button
            className="btn btn-outline-dark btn-pill px-8"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            className="btn btn-gradient btn-pill px-8"
            type="submit"
            form="kt_add_location_form"
          >
            Submit
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default AddLocationModal;
