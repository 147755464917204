import React from "react";
import Footer from "../components/common/Footer";
import Logo from "../components/common/Logo";
import Sidebar from "../components/common/Sidebar";
import Header from "../components/common/Header";
import MobileHeader from "../components/common/MobileHeader";
import ScrollTop from "../components/common/ScrollTop";
import { routes } from "../routes/configs/supplierSidebar";

const Layout = ({ children }) => {
  return (
    <div>
      <MobileHeader />
      <div className="d-flex flex-column flex-root">
        <div className="d-flex flex-row flex-column-fluid page">
          <div
            className="aside aside-left aside-fixed d-flex flex-column flex-row-auto shadow-none"
            id="kt_aside"
          >
            <Logo />

            <Sidebar routes={routes} />

            <Footer />
          </div>
          {/* <!--begin::Wrapper-->  */}
          <div
            className="d-flex flex-column flex-row-fluid wrapper"
            id="kt_wrapper"
          >
            <Header menu={false} />
            {/* <!--begin::Content-->  */}
            <div
              className="content d-flex flex-column flex-column-fluid"
              id="kt_content"
            >
              {children}
            </div>
            {/* <!-- end::Content--> */}
          </div>
        </div>
      </div>
      <ScrollTop />
    </div>
  );
};

export default Layout;
