import React from "react";
import { BASE_URL } from "../../utils/fetchData";

const SingleLocationDistributor = ({
  name,
  line1,
  line2,
  postalCode,
  comment,
  status,
  handleShowModal,
  handleDeleteLocation,
}) => {
  return (
    <tr>
      <td>{name}</td>
      <td>{`${line1}, ${line2}, ${postalCode}`}</td>
      <td>{comment}</td>
      <td>
        <span
          className={`color-${status === "PENDING" ? "warning" : "success"}`}
        >
          {status === "PENDING" ? "Waiting for Approval" : "Active"}
        </span>
      </td>
      <td>
        <button className="btn btn-sm btn-light btn-icon">
          <i
            className="flaticon2-rubbish-bin-delete-button color-primary"
            onClick={handleDeleteLocation}
          ></i>
        </button>
        <button
          className="btn btn-sm btn-light btn-icon"
          onClick={handleShowModal}
        >
          <i className="flaticon2-edit color-primary"></i>
        </button>
      </td>
    </tr>
  );
};

export default SingleLocationDistributor;
