import React from "react";
import Modal from "react-bootstrap/Modal";
import moment from "moment";

const AssignedListModal = ({ units, modalShow, handleClose }) => {
  return (
    <Modal
      size="lg"
      show={modalShow}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
    >
      <div className="card card-custom">
        <div className="card-header border-0">
          <div className="card-title">
            <div className="card-label">Assigned Stock Locations</div>
          </div>
        </div>
        <div className="card-body">
          <table className="table">
            <thead>
              <tr>
                <th>#</th>
                <th>Location</th>
                <th>Units</th>
                <th>Lot Number</th>
                <th>Expiry</th>
              </tr>
            </thead>
            <tbody>
              {units.length > 0 &&
                units.map((unit, index) => {
                  const {
                    lotNumber,
                    expiry,
                    assignedUnits,
                    Location: { name, line1, line2, postalCode },
                  } = unit;

                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{`${name}, ${line1}, ${line2}, ${postalCode}`}</td>
                      <td>{assignedUnits}</td>
                      <td>{lotNumber}</td>
                      {/* <td>{expiry && expiry.split("T")[0]}</td> */}
                      <td>{expiry && moment(expiry).format("DD MMM, YYYY")}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <div className="card-footer border-0 text-right">
          <button
            className="btn btn-outline-dark btn-pill px-8 mr-3"
            onClick={handleClose}
          >
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AssignedListModal;
