import React from "react";
import { useDispatch } from "react-redux";
import { BASE_URL } from "../../utils/fetchData";
import moment from "moment";
import { dispatchOrder } from "../../redux/actions/orderAction";

const SingleOrderDistributor = ({ order }) => {
  let {
    status,
    Stock: {
      Product: { name: productName, brand, image: productImage },
    },
    units,
    Location: { line1, line2, postalCode },
    lotNumber,
    unitsExpiry: expiry,
  } = order;

  expiry = moment(expiry).format("MMM DD, YYYY");

  const dispatch = useDispatch();

  const handleDispatch = () => {
    dispatch(dispatchOrder(order._id, "STANDARD"));
  };

  return (
    <tr>
      <td>
        <div className="d-flex align-items-start">
          <div className="symbol symbol-40 bg-light mr-2">
            <img
              src={`${BASE_URL}/${productImage}`}
              alt=""
              className="img-fluid"
            />
          </div>
          <div>
            <div className="font-weight-boldest font-size-lg color-primary">
              {productName}
            </div>
            <div className="color-secondary">{brand}</div>
          </div>
        </div>
      </td>
      <td>{lotNumber}</td>
      <td>{expiry}</td>
      <td>{`${line1}, ${line2}, ${postalCode}`}</td>
      <td>{units}</td>
      <td>
        {status === "CREATED" ? (
          <button className="btn btn-blue" onClick={handleDispatch}>
            Dispatch
          </button>
        ) : status === "COMPLETED" ? (
          <span className="badge rounded-pill badge-success">Completed</span>
        ) : (
          <span className="badge rounded-pill badge-warning">
            Waiting for Completion
          </span>
        )}
      </td>
    </tr>
  );
};

export default SingleOrderDistributor;
