import { deleteDataAPI, getDataAPI, postDataAPI } from "../../utils/fetchData";
import TYPES from "../constants";

export const QUERY_TYPES = {
  CREATE_QUERY: "CREATE_QUERY",
  GET_QUERIES: "GET_QUERIES",
  DELETE_QUERY: "DELETE_QUERY",
};

export const createQuery = (subject, message) => async (dispatch, getState) => {
  try {
    if (!subject || !message) {
      dispatch({
        type: TYPES.ALERT,
        payload: {
          error: "Please fill required fields",
        },
      });
      return;
    }
    const { usertype, token } = getState().auth;
    dispatch({ type: TYPES.ALERT, payload: { loading: true } });
    const res = await postDataAPI(
      `${usertype}/query`,
      { subject, message },
      token
    );
    console.log(res.data);
    if (res.data.success) {
      dispatch({ type: TYPES.ALERT, payload: { loading: false } });
      dispatch({
        type: TYPES.ALERT,
        payload: { success: res.data.message },
      });
      return Promise.resolve();
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: TYPES.ALERT,
      payload: {
        error: err.response.data.message,
      },
    });
    return Promise.reject();
  }
};

export const getQueries =
  (search, sort, limit, skip) => async (dispatch, getState) => {
    try {
      const { usertype, token } = getState().auth;
      dispatch({ type: TYPES.ALERT, payload: { loading: true } });
      const res = await getDataAPI(
        `${usertype}/queries?limit=${limit}&skip=${skip}&search=${search}&sort=${sort}`,
        token
      );
      if (res.data.success) {
        dispatch({
          type: QUERY_TYPES.GET_QUERIES,
          payload: {
            queries: res.data.data.queries,
            total: res.data.data.total,
          },
        });
        dispatch({ type: TYPES.ALERT, payload: { loading: false } });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: TYPES.ALERT,
        payload: {
          error: err.response.data.message,
        },
      });
    }
  };

export const deleteQuery = (id) => async (dispatch, getState) => {
  try {
    const { usertype, token } = getState().auth;
    dispatch({ type: TYPES.ALERT, payload: { loading: true } });
    const res = await deleteDataAPI(`${usertype}/query/${id}`, token);
    if (res.data.success) {
      dispatch({
        type: QUERY_TYPES.DELETE_QUERY,
        payload: id,
      });
      dispatch({ type: TYPES.ALERT, payload: { loading: false } });
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: TYPES.ALERT,
      payload: {
        error: err.response.data.message,
      },
    });
  }
};
