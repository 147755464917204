import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { seenNotification } from "../../redux/actions/notifyAction";
import { BASE_URL } from "../../utils/fetchData";
import getNotifyDetailView from "../../utils/notifyDetails";

const SingleNotificationHealthpro = ({ notification }) => {
  const {
    _id: id,
    message,
    messageMain,
    isSeen,
    recipientType,
    Sender: { profilePic, name },
  } = notification;

  const dispatch = useDispatch();

  const handleClick = () => {
    if (!isSeen) dispatch(seenNotification(id));
  };

  return (
    <Link to={getNotifyDetailView(notification, recipientType)}>
      <div
        className={`card card-custom card-border rounded-0 gutter-b ${
          !isSeen && "bg-light"
        }`}
        onClick={handleClick}
      >
        <div className="card-body p-3">
          <div className="row align-items-center">
            <div className="col-lg-12 d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <div className="text-center mr-3">
                  <div className="symbol symbol-50 bg-white">
                    <img
                      src={
                        profilePic
                          ? `${BASE_URL}/${profilePic}`
                          : "assets/media/users/default.jpg"
                      }
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div>
                  <div className="font-weight-boldest color-primary">
                    {name}
                  </div>
                  <div className="color-primary">
                    {message} <b>{messageMain}</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default SingleNotificationHealthpro;
