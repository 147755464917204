import React from "react";
import { BASE_URL } from "../../../utils/fetchData";
import Modal from "react-bootstrap/Modal";

const ViewSupplier = ({ supplier, modalShow, handleClose }) => {
  const { name, email, isActive, profilePic, phone, address } = supplier;
  return (
    <Modal
      size="lg"
      show={modalShow}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
    >
      <div className="modal-content rounded-0">
        <div className="modal-header">
          <h5 className="modal-title">Suppliers</h5>
        </div>

        <div className="modal-body">
          <table className="table table-vertical-center">
            <thead className="thead-light">
              <tr>
                <th>Name</th>
                <th>Phone</th>
                <th>Address</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="d-flex align-items-start">
                    <div className="symbol symbol-40 bg-light mr-2">
                      <img
                        src={
                          profilePic
                            ? `${BASE_URL}/${profilePic}`
                            : "assets/media/users/default.jpg"
                        }
                        alt=""
                      />
                    </div>
                    <div>
                      <div className="font-weight-boldest font-size-lg color-primary">
                        {name}
                      </div>
                      <div className="color-secondary">{email}</div>
                    </div>
                  </div>
                </td>
                <td>{phone} </td>
                <td>{address}</td>
                <td className={`color-${isActive ? "success" : "warning"}`}>
                  {isActive ? "Active" : "Inactive"}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="modal-footer">
          <button
            className="btn btn-blue"
            data-dismiss="modal"
            onClick={handleClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ViewSupplier;
